import React, { memo, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Menu } from "antd";
import { PaginationConfig } from "antd/es/pagination";
import { QuestionCategoryTypeToWord } from "models/questionnaireAnalytics";

import { serializeRange } from "hooks/useFilterConditions/rangeTransformer";
import { QuestionCategoryType } from "types/graphql";

import { FilterConditions } from "../../CommentFilter";

const DEFAULT_KEY = "default";

const StyledMenu = styled(Menu)`
  white-space: nowrap;
  overflow-x: scroll;
  height: 55px;
`;

const MENU_ORDER: QuestionCategoryType[] = [
  QuestionCategoryType.Repeatability,
  QuestionCategoryType.CustomerService,
  QuestionCategoryType.Deliciousness,
  QuestionCategoryType.Speed,
  QuestionCategoryType.Cleanliness,
];

type Props = {
  category?: QuestionCategoryType;
  filterConditions: Partial<FilterConditions>;
  setPagination: ({ pageSize, current }: PaginationConfig) => void;
};

export const HeaderMenu = memo<Props>(({ category, filterConditions, setPagination }) => {
  const linkState = useMemo(
    () => ({
      filterConditions: {
        ...filterConditions,
        range: serializeRange(filterConditions.range),
      },
    }),
    [filterConditions],
  );

  const initializePage = useCallback(() => {
    setPagination({ current: 1 });
  }, [setPagination]);

  return (
    <StyledMenu selectedKeys={[category ?? DEFAULT_KEY]} mode="horizontal" disabledOverflow>
      <Menu.Item key={DEFAULT_KEY}>
        <Link
          to="/questionnaireAnalytics/comment"
          state={linkState}
          replace
          onClick={initializePage}
        >
          すべて
        </Link>
      </Menu.Item>
      {Object.values(QuestionCategoryType)
        .sort((a, b) => (MENU_ORDER.indexOf(a) > MENU_ORDER.indexOf(b) ? 1 : -1))
        .map(
          (category) =>
            category !== QuestionCategoryType.Attribute && (
              <Menu.Item key={category}>
                <Link
                  to={`/questionnaireAnalytics/comment?category=${category}`}
                  state={linkState}
                  replace
                  onClick={initializePage}
                >
                  {QuestionCategoryTypeToWord[category]}
                </Link>
              </Menu.Item>
            ),
        )}
    </StyledMenu>
  );
});
