import React, { memo } from "react";
import { PartitionOutlined } from "@ant-design/icons";

import { PaymentTypesMenu } from "components/Layout/DashboardLayout/NavigationDrawer/HeadquartersMenu/PaymentTypesMenu";
import { MenuItem } from "components/Layout/DashboardLayout/NavigationDrawer/MenuItem";
import {
  SubMenu,
  SubMenuPropsWithoutFeatures,
} from "components/Layout/DashboardLayout/NavigationDrawer/SubMenu";
import { Company } from "components/Layout/DashboardLayout/types";
import { mobileOrderThemeSettingsUrl } from "pages/MobileOrderDesignSettings";
import { DashboardFeatureEnum } from "types/graphql";

type Props = {
  company?: Company;
} & SubMenuPropsWithoutFeatures;

export const CompanyMenu = memo<Props>(({ company, ...props }) => (
  <SubMenu
    key="corporation"
    icon={<PartitionOutlined />}
    title="業態管理"
    features={[DashboardFeatureEnum.CompanyManagement]}
    {...props}
  >
    <MenuItem route="autoTranslation" to="/autoTranslation" text="自動翻訳設定" />
    <MenuItem
      route="menuAutoCompulsoryAppetizer"
      to="/menu/autoCompulsoryAppetizer"
      text="お通し自動設定"
    />
    <PaymentTypesMenu company={company} />
    <MenuItem
      route="mobileOrderDesign"
      to={mobileOrderThemeSettingsUrl}
      text="モバイルオーダーデザイン設定"
    />
  </SubMenu>
));
