import React, { memo, useCallback, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Menu } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { Shop } from "types/graphql";

type Props = {
  shop: Pick<Shop, "shopId" | "name"> | null;
};

export const ShopCashlessSettingHeader = memo<Props>(({ shop }) => {
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const { pathname } = useLocation();
  const selectedKey = pathname.split("/")[4];
  const selectedKeys = useMemo(() => (selectedKey ? [selectedKey] : []), [selectedKey]);

  return (
    <>
      <PageHeader onBack={goBack} title={shop?.name ?? ""} />
      {shop && (
        <Menu selectedKeys={selectedKeys} mode="horizontal">
          <Menu.Item key="storeMeta">
            <Link to={`/cashlessSetting/shop/${shop.shopId}/storeMeta/edit`} replace>
              店舗詳細情報
            </Link>
          </Menu.Item>
          <Menu.Item key="providerSetting">
            <Link to={`/cashlessSetting/shop/${shop.shopId}/providerSetting/edit`} replace>
              決済情報設定
            </Link>
          </Menu.Item>
        </Menu>
      )}
    </>
  );
});
