export const convertTelephoneNumber = (telephoneNumber: string): string => {
  if (telephoneNumber.startsWith("0")) {
    return "+81" + telephoneNumber.slice(1);
  }
  return telephoneNumber;
};

export const revertTelephoneNumber = (telephoneNumber: string): string => {
  if (telephoneNumber.startsWith("+81")) {
    return "0" + telephoneNumber.slice(3);
  }
  return telephoneNumber;
};
