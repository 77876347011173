import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import { Alert } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { getTecAggregationIntegrationTypeName } from "models/tecAggregationIntegrationType";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { useCompany } from "hooks/useCompany";
import { useShop } from "hooks/useShop";
import { TecAggregationConfigInsertInput, TecAggregationIntegrationTypeEnum } from "types/graphql";

import { useEditTecAggregationConfigFormPermission } from "./EditTecAggregationConfigForm/useEditTecAggregationConfigFormPermission";
import { EditTecAggregationConfigForm } from "./EditTecAggregationConfigForm";
import {
  useEditTecAggregationConfigGetTecAggregationConfigsQuery,
  useEditTecAggregationConfigUpsertTecAggregationConfigMutation,
} from "./queries";

export const EditTecAggregationConfig = () => {
  const [shop] = useShop();
  const shopId = shop?.shopId;
  const { integrationType } = useParams<{ integrationType: TecAggregationIntegrationTypeEnum }>();
  const [, , updateCompanyData] = useCompany();

  const { data, error, refetch } = useEditTecAggregationConfigGetTecAggregationConfigsQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );

  const tecAggregationConfig = data?.tecAggregationConfig?.find(
    (config) => config.integrationType === integrationType,
  );

  const [upsertTecAggregationConfigMutation, { loading: submitting }] =
    useEditTecAggregationConfigUpsertTecAggregationConfigMutation();

  const { canEditForm, canToggleStatus } = useEditTecAggregationConfigFormPermission();

  const onSubmit = useCallback(
    async (input: Omit<TecAggregationConfigInsertInput, "shopId">) => {
      if (!shopId) return;
      if (!canEditForm && !canToggleStatus) return;
      if (!integrationType) return;

      const id = tecAggregationConfig?.id ?? uuidv4();
      const tecAggregationConfigDto = {
        id,
        shopId,
        integrationType,
        ...input,
      };

      try {
        await upsertTecAggregationConfigMutation({
          variables: {
            tecAggregationConfig: tecAggregationConfigDto,
          },
        });
        message.success("更新しました");
      } catch {
        message.error("更新に失敗しました");
      }
      await refetch();
      await updateCompanyData();
    },
    [
      tecAggregationConfig,
      shopId,
      upsertTecAggregationConfigMutation,
      refetch,
      canEditForm,
      canToggleStatus,
      integrationType,
      updateCompanyData,
    ],
  );

  const onFormValidationError = useCallback(
    ({ formValidationError: _ }: { formValidationError: ValidateErrorEntity }) => {
      message.error("作成に失敗しました");
    },
    [],
  );

  const tecAggregationConfigs = data?.tecAggregationConfig;
  const pageTitle =
    tecAggregationConfigs && tecAggregationConfigs.length > 0
      ? tecAggregationConfigs
          .map(({ integrationType }) =>
            getTecAggregationIntegrationTypeName(integrationType).slice(0, -2),
          )
          .join("/") + "連携"
      : "TECフォーマット連携";

  return (
    <DashboardLayout title={pageTitle}>
      <PageHeader title="設定" footer={<ShopSelector />} />
      {!shop && <Paragraph>店舗を選択してください。</Paragraph>}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      {shop &&
        integrationType &&
        Object.values(TecAggregationIntegrationTypeEnum).includes(integrationType) && (
          <EditTecAggregationConfigForm
            tecAggregationConfig={tecAggregationConfig}
            onSubmit={onSubmit}
            onFormValidationError={onFormValidationError}
            loading={submitting}
            integrationType={integrationType}
          />
        )}
    </DashboardLayout>
  );
};
