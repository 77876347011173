import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditShopCashlessSettingStoreMetaFormItem } from "../useEditShopCashlessSettingStoreMetaForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const PostalCodeField = memo<Props>((props) => (
  <EditShopCashlessSettingStoreMetaFormItem
    label="郵便番号(ハイフン無し)"
    name="postalCode"
    rules={[
      {
        required: true,
        pattern: /^[0-9]{7}$/,
        message: "郵便番号は7桁の半角数字で入力してください",
      },
    ]}
    {...props}
  >
    <Input placeholder="例：1050023" />
  </EditShopCashlessSettingStoreMetaFormItem>
));
