import React, { memo } from "react";
import styled from "styled-components";
import { Button } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { AdyenPaymentStoreMeta } from "pages/EditShopCashlessSettingStoreMeta/types";

import { AreaField } from "./AreaField";
import { CityField } from "./CityField";
import { CityWardField } from "./CityWardField";
import { PostalCodeField } from "./PostalCodeField";
import { ShopNameEnField } from "./ShopNameEnField";
import { TelephoneNumberField } from "./TelephoneNumberField";
import { TownField } from "./TownField";
import {
  EditShopCashlessSettingStoreMetaFormValues,
  useEditShopCashlessSettingStoreMetaForm,
} from "./useEditShopCashlessSettingStoreMetaForm";

type Props = {
  adyenPaymentStoreMeta: AdyenPaymentStoreMeta | null;
  loading: boolean;
  onSubmit: (values: EditShopCashlessSettingStoreMetaFormValues) => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
};

const StyledForm = styled(Form)`
  max-width: 640px;
`;

export const EditShopCashlessSettingStoreMetaForm = memo<Props>(
  ({ adyenPaymentStoreMeta, loading, onSubmit, onFormValidationError }) => {
    const { form, initialValues, submit } = useEditShopCashlessSettingStoreMetaForm({
      adyenPaymentStoreMeta,
      onSubmit,
      onFormValidationError,
    });

    return (
      <>
        <FormContent>
          <StyledForm
            name="paymentSHopCashlessSetting"
            form={form}
            initialValues={initialValues}
            layout="vertical"
          >
            <ShopNameEnField />
            <TelephoneNumberField />
            <PostalCodeField />
            <CityField />
            <CityWardField />
            <TownField />
            <AreaField />
          </StyledForm>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button type="primary" onClick={submit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
