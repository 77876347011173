import useAsyncFn from "react-use/esm/useAsyncFn";
import { isDiniiApiError } from "util/type/diniiApi";

import { message } from "components/antd/message";
import { useDinii } from "hooks/useDinii";

export const usePikaichiUpload = () => {
  const [dinii, getContext] = useDinii();

  const [{ loading }, submit] = useAsyncFn(
    async ({ date }: { date: Date }) => {
      const context = await getContext();

      if (!context) {
        return;
      }

      try {
        await dinii.pikaichi.upload(context, date);
        message.success("転送しました");
      } catch (err) {
        if (isDiniiApiError(err) && err.response?.data.title) {
          return message.error(`${err.response.data.title}: ${err.response.data.message}`);
        }

        message.error("転送に失敗しました");
      }
    },
    [dinii.pikaichi, getContext],
  );

  return {
    loading,
    submit,
  };
};
