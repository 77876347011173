import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const MobileOrderDesignSettingsGetSettings = gql`
    query MobileOrderDesignSettingsGetSettings($shopId: uuid!, $companyId: uuid!) {
  company(where: {id: {_eq: $companyId}}) {
    id
    enableDarkTheme
  }
  mobileOrderWelcomeScreenSetting(where: {companyId: {_eq: $companyId}}) {
    companyId
    imageUrl
    welcomeMessage
  }
  shop_by_pk(shopId: $shopId) {
    shopId
    name
  }
}
    `;
export const MobileOrderThemeSettingsSaveSettings = gql`
    mutation MobileOrderThemeSettingsSaveSettings($companyId: uuid!, $enableDarkTheme: Boolean!, $welcomeScreenSetting: mobileOrderWelcomeScreenSetting_insert_input!) {
  update_company(
    where: {id: {_eq: $companyId}}
    _set: {enableDarkTheme: $enableDarkTheme}
  ) {
    returning {
      id
      enableDarkTheme
    }
  }
  insert_mobileOrderWelcomeScreenSetting_one(
    object: $welcomeScreenSetting
    on_conflict: {constraint: mobileOrderWelcomeScreenSetting_companyId_key, update_columns: [welcomeMessage, imageUrl]}
  ) {
    id
  }
}
    `;
export type MobileOrderDesignSettingsGetSettingsQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  companyId: Types.Scalars['uuid'];
}>;


export type MobileOrderDesignSettingsGetSettingsQuery = (
  { __typename?: 'query_root' }
  & { company: Array<(
    { __typename?: 'company' }
    & Pick<Types.Company, 'id' | 'enableDarkTheme'>
  )>, mobileOrderWelcomeScreenSetting: Array<(
    { __typename?: 'mobileOrderWelcomeScreenSetting' }
    & Pick<Types.MobileOrderWelcomeScreenSetting, 'companyId' | 'imageUrl' | 'welcomeMessage'>
  )>, shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);

export type MobileOrderThemeSettingsSaveSettingsMutationVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
  enableDarkTheme: Types.Scalars['Boolean'];
  welcomeScreenSetting: Types.MobileOrderWelcomeScreenSettingInsertInput;
}>;


export type MobileOrderThemeSettingsSaveSettingsMutation = (
  { __typename?: 'mutation_root' }
  & { update_company?: Types.Maybe<(
    { __typename?: 'company_mutation_response' }
    & { returning: Array<(
      { __typename?: 'company' }
      & Pick<Types.Company, 'id' | 'enableDarkTheme'>
    )> }
  )>, insert_mobileOrderWelcomeScreenSetting_one?: Types.Maybe<(
    { __typename?: 'mobileOrderWelcomeScreenSetting' }
    & Pick<Types.MobileOrderWelcomeScreenSetting, 'id'>
  )> }
);


export const MobileOrderDesignSettingsGetSettingsDocument = gql`
    query MobileOrderDesignSettingsGetSettings($shopId: uuid!, $companyId: uuid!) {
  company(where: {id: {_eq: $companyId}}) {
    id
    enableDarkTheme
  }
  mobileOrderWelcomeScreenSetting(where: {companyId: {_eq: $companyId}}) {
    companyId
    imageUrl
    welcomeMessage
  }
  shop_by_pk(shopId: $shopId) {
    shopId
    name
  }
}
    `;

/**
 * __useMobileOrderDesignSettingsGetSettingsQuery__
 *
 * To run a query within a React component, call `useMobileOrderDesignSettingsGetSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMobileOrderDesignSettingsGetSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMobileOrderDesignSettingsGetSettingsQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useMobileOrderDesignSettingsGetSettingsQuery(baseOptions: Apollo.QueryHookOptions<MobileOrderDesignSettingsGetSettingsQuery, MobileOrderDesignSettingsGetSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MobileOrderDesignSettingsGetSettingsQuery, MobileOrderDesignSettingsGetSettingsQueryVariables>(MobileOrderDesignSettingsGetSettingsDocument, options);
      }
export function useMobileOrderDesignSettingsGetSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MobileOrderDesignSettingsGetSettingsQuery, MobileOrderDesignSettingsGetSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MobileOrderDesignSettingsGetSettingsQuery, MobileOrderDesignSettingsGetSettingsQueryVariables>(MobileOrderDesignSettingsGetSettingsDocument, options);
        }
export type MobileOrderDesignSettingsGetSettingsQueryHookResult = ReturnType<typeof useMobileOrderDesignSettingsGetSettingsQuery>;
export type MobileOrderDesignSettingsGetSettingsLazyQueryHookResult = ReturnType<typeof useMobileOrderDesignSettingsGetSettingsLazyQuery>;
export type MobileOrderDesignSettingsGetSettingsQueryResult = Apollo.QueryResult<MobileOrderDesignSettingsGetSettingsQuery, MobileOrderDesignSettingsGetSettingsQueryVariables>;
export const MobileOrderThemeSettingsSaveSettingsDocument = gql`
    mutation MobileOrderThemeSettingsSaveSettings($companyId: uuid!, $enableDarkTheme: Boolean!, $welcomeScreenSetting: mobileOrderWelcomeScreenSetting_insert_input!) {
  update_company(
    where: {id: {_eq: $companyId}}
    _set: {enableDarkTheme: $enableDarkTheme}
  ) {
    returning {
      id
      enableDarkTheme
    }
  }
  insert_mobileOrderWelcomeScreenSetting_one(
    object: $welcomeScreenSetting
    on_conflict: {constraint: mobileOrderWelcomeScreenSetting_companyId_key, update_columns: [welcomeMessage, imageUrl]}
  ) {
    id
  }
}
    `;
export type MobileOrderThemeSettingsSaveSettingsMutationFn = Apollo.MutationFunction<MobileOrderThemeSettingsSaveSettingsMutation, MobileOrderThemeSettingsSaveSettingsMutationVariables>;

/**
 * __useMobileOrderThemeSettingsSaveSettingsMutation__
 *
 * To run a mutation, you first call `useMobileOrderThemeSettingsSaveSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMobileOrderThemeSettingsSaveSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mobileOrderThemeSettingsSaveSettingsMutation, { data, loading, error }] = useMobileOrderThemeSettingsSaveSettingsMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      enableDarkTheme: // value for 'enableDarkTheme'
 *      welcomeScreenSetting: // value for 'welcomeScreenSetting'
 *   },
 * });
 */
export function useMobileOrderThemeSettingsSaveSettingsMutation(baseOptions?: Apollo.MutationHookOptions<MobileOrderThemeSettingsSaveSettingsMutation, MobileOrderThemeSettingsSaveSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MobileOrderThemeSettingsSaveSettingsMutation, MobileOrderThemeSettingsSaveSettingsMutationVariables>(MobileOrderThemeSettingsSaveSettingsDocument, options);
      }
export type MobileOrderThemeSettingsSaveSettingsMutationHookResult = ReturnType<typeof useMobileOrderThemeSettingsSaveSettingsMutation>;
export type MobileOrderThemeSettingsSaveSettingsMutationResult = Apollo.MutationResult<MobileOrderThemeSettingsSaveSettingsMutation>;
export type MobileOrderThemeSettingsSaveSettingsMutationOptions = Apollo.BaseMutationOptions<MobileOrderThemeSettingsSaveSettingsMutation, MobileOrderThemeSettingsSaveSettingsMutationVariables>;