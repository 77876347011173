import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditShopCashlessSettingStoreMetaFormItem } from "../useEditShopCashlessSettingStoreMetaForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const ShopNameEnField = memo<Props>((props) => (
  <EditShopCashlessSettingStoreMetaFormItem
    label="店舗名(ローマ字)"
    name="shopNameEn"
    rules={[
      {
        required: true,
        pattern: /^[a-zA-Z0-9!-/:-@[-`{-~ ]+$/,
        message: "店舗名(ローマ字)は半角英数字記号で入力してください",
      },
    ]}
    {...props}
  >
    {/* cspell:disable-next */}
    <Input placeholder="dinii hamamatsucho-ten" />
  </EditShopCashlessSettingStoreMetaFormItem>
));
