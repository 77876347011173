import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditShopCashlessSettingStoreMetaFormItem } from "../useEditShopCashlessSettingStoreMetaForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const CityField = memo<Props>((props) => (
  <EditShopCashlessSettingStoreMetaFormItem
    label="都道府県(英語)"
    name="city"
    rules={[
      {
        required: true,
        pattern: /^[a-zA-Z]*$/,
        message: "都道府県(英語)は半角英字で入力してください",
      },
    ]}
    {...props}
  >
    {/* cspell:disable-next */}
    <Input placeholder="例: Kanagawa" />
  </EditShopCashlessSettingStoreMetaFormItem>
));
