import React, { memo } from "react";
import { Switch } from "antd";

import { FormItemProps, withFormDependencies } from "components/antd/Form";
import { InputNumber } from "components/Input/InputNumber";

import { EditShopFormItem } from "../useEditShopForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const CheckInElapsedTimeAlertThresholdMinutesField = memo<Props>(({ ...props }) => (
  <>
    <EditShopFormItem
      label="入店時刻経過アラート"
      name="enableCheckInElapsedTimeAlert"
      valuePropName="checked"
    >
      <Switch />
    </EditShopFormItem>
    <EditShopFormItem.NonProperty
      noStyle
      shouldUpdate={withFormDependencies(({ enableCheckInElapsedTimeAlert }) => [
        enableCheckInElapsedTimeAlert,
      ])}
    >
      {({ getFieldValue }) => {
        const enableCheckInElapsedTimeAlert = getFieldValue("enableCheckInElapsedTimeAlert");
        return (
          <EditShopFormItem
            label="入店時刻からの経過時間"
            name="checkInElapsedTimeAlertThresholdMinutes"
            {...props}
            rules={[
              {
                validator: async (_, checkInElapsedTimeAlertThresholdMinutes: number) => {
                  if (typeof checkInElapsedTimeAlertThresholdMinutes !== "number") {
                    throw new Error("入店時刻からの経過時間を入力してください");
                  }

                  if (checkInElapsedTimeAlertThresholdMinutes < 1) {
                    throw new Error("1分以上の数値を入力してください");
                  }
                },
              },
            ]}
          >
            <InputNumber
              disabled={!enableCheckInElapsedTimeAlert}
              formatter={(value) => `${value}分`}
              parser={(value) => (value ?? "").replace("分", "")}
              min={0}
            />
          </EditShopFormItem>
        );
      }}
    </EditShopFormItem.NonProperty>
  </>
));
