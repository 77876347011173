import React, { memo } from "react";
import { Switch } from "antd";

import { FormItemProps, withFormDependencies } from "components/antd/Form";
import { InputNumber } from "components/Input/InputNumber";

import { EditShopFormItem } from "../useEditShopForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const LatestOrderElapsedTimeAlertThresholdMinutesField = memo<Props>(({ ...props }) => (
  <>
    <EditShopFormItem
      label="最終注文時刻経過アラート"
      name="enableLatestOrderElapsedTimeAlert"
      valuePropName="checked"
    >
      <Switch />
    </EditShopFormItem>
    <EditShopFormItem.NonProperty
      noStyle
      shouldUpdate={withFormDependencies(({ enableLatestOrderElapsedTimeAlert }) => [
        enableLatestOrderElapsedTimeAlert,
      ])}
    >
      {({ getFieldValue }) => {
        const enableLatestOrderElapsedTimeAlert = getFieldValue(
          "enableLatestOrderElapsedTimeAlert",
        );
        return (
          <EditShopFormItem
            label="最終注文時刻からの経過時間"
            name="latestOrderElapsedTimeAlertThresholdMinutes"
            {...props}
            rules={[
              {
                validator: async (_, latestOrderElapsedTimeAlertThresholdMinutes: number) => {
                  if (typeof latestOrderElapsedTimeAlertThresholdMinutes !== "number") {
                    throw new Error("最終注文時刻からの経過時間を入力してください");
                  }

                  if (latestOrderElapsedTimeAlertThresholdMinutes < 1) {
                    throw new Error("1分以上の数値を入力してください");
                  }
                },
              },
            ]}
          >
            <InputNumber
              disabled={!enableLatestOrderElapsedTimeAlert}
              formatter={(value) => `${value}分`}
              parser={(value) => (value ?? "").replace("分", "")}
              min={0}
            />
          </EditShopFormItem>
        );
      }}
    </EditShopFormItem.NonProperty>
  </>
));
