import { useCallback, useEffect, useMemo } from "react";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

import { createFormItem, Form } from "components/antd/Form";

import { AdyenPaymentStoreMerchantCategory } from "../types";

export type AddShopCashlessSettingMerchantCategoryFormValues = Pick<
  AdyenPaymentStoreMerchantCategory,
  "merchantCategory"
>;

export const AddShopCashlessSettingMerchantCategoryFormItem =
  createFormItem<AddShopCashlessSettingMerchantCategoryFormValues>();

export const useAddShopCashlessSettingMerchantCategoryForm = ({
  merchantCategory,
  onSubmit,
  onFormValidationError,
}: {
  merchantCategory: AdyenPaymentStoreMerchantCategory | null;
  onSubmit: (values: AddShopCashlessSettingMerchantCategoryFormValues) => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
}) => {
  const [form] = Form.useForm<AddShopCashlessSettingMerchantCategoryFormValues>();
  const initialValues = useMemo(
    () => ({
      merchantCategory: merchantCategory?.merchantCategory ?? "",
    }),
    [merchantCategory],
  );

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const submit = useCallback(async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      onSubmit(values);
    } catch (e) {
      if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
    }
  }, [form, onFormValidationError, onSubmit]);

  return { form, initialValues, submit };
};
