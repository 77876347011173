import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Alert } from "antd";
import dayjs from "dayjs";
import { QuestionCategoryTypeToWord } from "models/questionnaireAnalytics";
import { exportCsv } from "util/exportCsv";
import { isNotNullable } from "util/type/primitive";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { viewport } from "constants/viewport";
import { useCorporation } from "hooks/useCorporation";
import { useFilterConditions } from "hooks/useFilterConditions";
import { deserializeRange, serializeRange } from "hooks/useFilterConditions/rangeTransformer";
import { usePagination } from "hooks/usePagination";
import { useQueryParams } from "hooks/useQuery";
import { QuestionCategoryType } from "types/graphql";

import { CommentFilter, FilterConditions, SerializedFilterConditions } from "./CommentFilter";
import { CommentList } from "./CommentList";
import {
  useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCountQuery,
  useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsQuery,
  useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireQuery,
  useQuestionnaireAnalyticsCommentsMetricsGetShopsQuery,
} from "./queries";

const Title = styled.div`
  @media ${viewport.smartphone}, ${viewport.tablet} {
    padding: 0 16px;
  }
`;

export const QuestionnaireAnalyticsCommentsMetrics = () => {
  const [corporation] = useCorporation();
  const [pagination, setPagination] = usePagination();
  const corporationId = corporation?.corporationId;

  const query = useQueryParams();
  const category = (query.get("category") as QuestionCategoryType | null) ?? undefined;

  const initialRange = useMemo<[dayjs.Dayjs, dayjs.Dayjs]>(() => {
    const now = dayjs();

    return [now.subtract(7, "day").startOf("day"), now.endOf("day")];
  }, []);

  const { filterConditions, updateFilterCondition } = useFilterConditions<
    FilterConditions,
    SerializedFilterConditions
  >(
    {
      range: initialRange,
    },
    undefined,
    {
      serialize: ({ range, ...filterConditions }) => ({
        ...filterConditions,
        range: serializeRange(range),
      }),
      deserialize: ({ range, ...filterConditions }) => ({
        ...filterConditions,
        range: deserializeRange(range),
      }),
    },
  );
  const [selectedShopIds, setSelectedShopIds] = useState<string[]>(filterConditions.shopIds ?? []);

  const {
    data: getQuestionnaire,
    error: errorGetQuestionnaire,
    loading: loadingQuestionnaire,
  } = useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireQuery(
    corporationId ? { variables: { corporationId } } : { skip: true },
  );

  const questionnaireId = getQuestionnaire?.questionnaire[0]?.id;

  const { data: getShopsData } = useQuestionnaireAnalyticsCommentsMetricsGetShopsQuery(
    corporationId && questionnaireId
      ? {
          variables: { corporationId, questionnaireId },
          onCompleted: (data) => {
            if (!filterConditions.shopIds) {
              const targetShopIds = data.corporation[0]?.companies
                .sort((a, b) => a.name.localeCompare(b.name))
                .flatMap(({ shops }) =>
                  shops.sort((a, b) => a.name.localeCompare(b.name)).map(({ shopId }) => shopId),
                );

              updateFilterCondition({ shopIds: targetShopIds });
              setSelectedShopIds(targetShopIds ?? []);
            }
          },
        }
      : { skip: true },
  );
  const companies = useMemo(
    () => getShopsData?.corporation[0]?.companies ?? [],
    [getShopsData?.corporation],
  );

  const shopIds = useMemo(() => filterConditions.shopIds ?? [], [filterConditions.shopIds]);

  const range = useMemo(
    () =>
      !filterConditions.range?.[0] || !filterConditions.range[1]
        ? null
        : {
            startAt: filterConditions.range[0].toISOString(),
            endAt: filterConditions.range[1].toISOString(),
          },

    [filterConditions.range],
  );

  const {
    data: getQuestionnaireComments,
    error: getQuestionnaireCommentsError,
    loading: getQuestionnaireCommentsLoading,
  } = useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsQuery(
    corporationId && questionnaireId && shopIds.length > 0 && range
      ? {
          variables: {
            input: {
              questionnaireId,
              corporationId,
              shopIds,
              ...range,
              answererSegments: filterConditions.answerSegments,
              page: pagination.current,
              perPage: pagination.defaultPageSize,
              category,
            },
          },
        }
      : { skip: true },
  );

  const questionnaireComments = getQuestionnaireComments?.questionnaireComments;

  const { data: getQuestionnaireCommentsCount } =
    useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCountQuery(
      corporationId && questionnaireId && shopIds.length > 0 && range
        ? {
            variables: {
              input: {
                questionnaireId,
                corporationId,
                shopIds,
                ...range,
                answererSegments: filterConditions.answerSegments,
                category,
              },
            },
          }
        : { skip: true },
    );
  const totalCommentsCount =
    getQuestionnaireCommentsCount?.questionnaireCommentsCount.totalCommentsCount ?? 0;

  const filteredQuestionnaireComments = useMemo(() => {
    let filteredComments = questionnaireComments ?? [];
    if (category) {
      filteredComments = filteredComments.filter(
        ({ category: questionCategory }) => questionCategory === (category as QuestionCategoryType),
      );
    }

    const { answererSatisfactions } = filterConditions;
    const threshold = 80;
    if (answererSatisfactions && answererSatisfactions.length > 0) {
      filteredComments = filteredComments.filter(({ score }) =>
        answererSatisfactions.some((value) => {
          switch (value) {
            case "high":
              return score >= threshold;
            case "low":
              return score < threshold;
          }
        }),
      );
    }

    return filteredComments;
  }, [questionnaireComments, category, filterConditions]);

  const shouldShowError = errorGetQuestionnaire || getQuestionnaireCommentsError;
  const loading = loadingQuestionnaire || getQuestionnaireCommentsLoading;

  const handleExportCsv = useCallback(() => {
    const startDate = filterConditions.range?.[0];
    const endDate = filterConditions.range?.[1];

    if (!startDate || !endDate || !filteredQuestionnaireComments) return;

    const startDateString = startDate.format("YYYYMMDD");
    const endDateString = endDate.format("YYYYMMDD");

    exportCsv({
      fileName: `questionnaire_comments_${startDateString}_${endDateString}`,
      columnHeaders: [
        "店舗名",
        "来店日時",
        "LINE ID",
        "性別",
        "年齢",
        "来店回数",
        "カテゴリ",
        "スコア",
        "項目",
        "コメント内容",
      ],
      rows: filteredQuestionnaireComments.map(
        ({
          shopName,
          visitedAt,
          lineId,
          gender,
          age,
          checkedInCount,
          category,
          score,
          title,
          text,
        }) => [
          shopName,
          dayjs(visitedAt).format("YYYY/MM/DD HH:mm"),
          lineId,
          gender ?? "未回答",
          isNotNullable(age) ? age.toString() : "未回答",
          checkedInCount.toString(),
          QuestionCategoryTypeToWord[category],
          score.toString(),
          title ?? "未回答",
          text,
        ],
      ),
    });
  }, [filterConditions.range, filteredQuestionnaireComments]);

  return (
    <DashboardLayout title="コメント一覧">
      <PageHeader
        title={<Title>コメント一覧</Title>}
        footer={
          <CommentFilter
            companies={companies}
            filterConditions={filterConditions}
            updateFilterCondition={updateFilterCondition}
            downloadQuestionnaireComments={handleExportCsv}
            selectedShopIds={selectedShopIds}
            setSelectedShopIds={setSelectedShopIds}
          />
        }
      />

      {shouldShowError && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <CommentList
        questionnaireComments={filteredQuestionnaireComments}
        totalCommentCount={totalCommentsCount}
        category={category}
        loading={loading}
        filterConditions={filterConditions}
        pagination={pagination}
        setPagination={setPagination}
      />
    </DashboardLayout>
  );
};
