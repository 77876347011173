import React, { useCallback } from "react";
import styled from "styled-components";
import { Alert, Button, Card, DatePicker } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";

// eslint-disable-next-line no-restricted-imports
import { Form, FormItem } from "components/antd/Form";
import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { FormActions } from "components/Form/FormActions";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { useCompany } from "hooks/useCompany";
import { useShop } from "hooks/useShop";

import { usePikaichiUploadGetShopsQuery } from "./queries";
import { usePikaichiGetZipToUpload } from "./usePikaichiGetZipToUpload";
import { usePikaichiUpload } from "./usePikaichiUpload";
import { usePikaichiUploadForm } from "./usePikaichiUploadForm";

const FormContainer = styled.div`
  margin: 48px 0px;
`;

export const PikaichiUpload = () => {
  const { form, getValues } = usePikaichiUploadForm();

  const [currentCompany] = useCompany();
  const [currentShop] = useShop();
  const { data: shopsData, error } = usePikaichiUploadGetShopsQuery(
    currentCompany ? { variables: { companyId: currentCompany.id } } : { skip: true },
  );

  const shop = shopsData?.shops.find((s) => s.shopId === currentShop?.shopId);

  const { loading: loadingGetZipToUpload, submit: getZipToUpload } = usePikaichiGetZipToUpload();
  const { loading: loadingUpload, submit: upload } = usePikaichiUpload();

  const loading = loadingGetZipToUpload || loadingUpload;

  const onClickUpload = useCallback(async () => {
    try {
      await form.validateFields();
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const date = getValues().date!.toDate();

      await upload({ date });
    } catch (e) {
      message.error("アップロードに失敗しました");
    }
  }, [form, getValues, upload]);

  const onClickDownload = useCallback(async () => {
    try {
      await form.validateFields();
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const date = getValues().date!.toDate();

      await getZipToUpload({ date });
    } catch (e) {
      message.error("ダウンロードに失敗しました");
    }
  }, [form, getValues, getZipToUpload]);

  // TODO: pikaichiConfig.ftpHost 等が埋まっていない場合は設定画面で設定させるよう促す？

  return (
    <DashboardLayout title="ぴかいちナビ連携">
      <PageHeader title="連携処理" footer={<ShopSelector />} />
      {!shop && <Paragraph>店舗を選択してください。</Paragraph>}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <Card>
        {shop && !shop?.pikaichiConfig?.enabled && (
          <Paragraph>ぴかいちナビ集計が有効になっていない店舗です。</Paragraph>
        )}

        {shop?.pikaichiConfig?.enabled && (
          <>
            <Paragraph>集計データをぴかいちナビに転送します。</Paragraph>

            <FormContainer>
              <Form name="pikaichiUpload" form={form} layout="vertical">
                <FormItem label="対象日" name="date" rules={[{ required: true, type: "object" }]}>
                  <DatePicker />
                </FormItem>
              </Form>
            </FormContainer>

            <FormActions>
              <Button
                type="link"
                loading={loadingGetZipToUpload}
                onClick={onClickDownload}
                disabled={loading}
              >
                転送ファイルをダウンロード
              </Button>

              <Button
                type="primary"
                loading={loadingUpload}
                onClick={onClickUpload}
                disabled={loading}
              >
                ぴかいちナビに転送
              </Button>
            </FormActions>
          </>
        )}
      </Card>
    </DashboardLayout>
  );
};
