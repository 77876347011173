import React, { memo } from "react";
import styled from "styled-components";
import { useWatch } from "antd/es/form/Form";
import { FormInstance } from "antd/lib";

import MobileOrderDesignDark from "../../assets/mobile_order_design_dark.png";
import MobileOrderDesignLight from "../../assets/mobile_order_design_light.png";

import { MobileOrderDesignSettingsFormValues } from ".";

const PreviewImage = styled.img`
  width: ${300}px;
  height: ${632}px;
`;

type Props = {
  form: FormInstance<MobileOrderDesignSettingsFormValues>;
};

export const DarkThemePreviewImage = memo<Props>(({ form }) => {
  const themeSetting: MobileOrderDesignSettingsFormValues["themeSetting"] = useWatch(
    "themeSetting",
    form,
  );

  return (
    <PreviewImage
      alt="モバイルオーダーテーマプレビュー画像"
      src={themeSetting === "dark" ? MobileOrderDesignDark : MobileOrderDesignLight}
    />
  );
});
