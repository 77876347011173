import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMeta = gql`
    query EditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMeta($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    name
  }
  adyenPaymentStoreMeta(where: {shopId: {_eq: $shopId}}, limit: 1) {
    shopNameEn
    telephoneNumber
    postalCode
    city
    address1
    address2
    address3
  }
}
    `;
export const EditShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMeta = gql`
    mutation EditShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMeta($input: AdyenPaymentUpsertStoreInput!) {
  onlinePaymentRoot {
    upsertAdyenPaymentStoreMeta(input: $input) {
      result
    }
  }
}
    `;
export type EditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMetaQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMetaQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )>, adyenPaymentStoreMeta: Array<(
    { __typename?: 'adyenPaymentStoreMeta' }
    & Pick<Types.AdyenPaymentStoreMeta, 'shopNameEn' | 'telephoneNumber' | 'postalCode' | 'city' | 'address1' | 'address2' | 'address3'>
  )> }
);

export type EditShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMetaMutationVariables = Types.Exact<{
  input: Types.AdyenPaymentUpsertStoreInput;
}>;


export type EditShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMetaMutation = (
  { __typename?: 'mutation_root' }
  & { onlinePaymentRoot?: Types.Maybe<(
    { __typename?: 'onlinePaymentRootMutation' }
    & { upsertAdyenPaymentStoreMeta: (
      { __typename?: 'AdyenPaymentUpsertStoreResult' }
      & Pick<Types.AdyenPaymentUpsertStoreResult, 'result'>
    ) }
  )> }
);


export const EditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMetaDocument = gql`
    query EditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMeta($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    name
  }
  adyenPaymentStoreMeta(where: {shopId: {_eq: $shopId}}, limit: 1) {
    shopNameEn
    telephoneNumber
    postalCode
    city
    address1
    address2
    address3
  }
}
    `;

/**
 * __useEditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMetaQuery__
 *
 * To run a query within a React component, call `useEditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMetaQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMetaQuery(baseOptions: Apollo.QueryHookOptions<EditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMetaQuery, EditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMetaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMetaQuery, EditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMetaQueryVariables>(EditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMetaDocument, options);
      }
export function useEditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMetaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMetaQuery, EditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMetaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMetaQuery, EditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMetaQueryVariables>(EditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMetaDocument, options);
        }
export type EditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMetaQueryHookResult = ReturnType<typeof useEditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMetaQuery>;
export type EditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMetaLazyQueryHookResult = ReturnType<typeof useEditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMetaLazyQuery>;
export type EditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMetaQueryResult = Apollo.QueryResult<EditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMetaQuery, EditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMetaQueryVariables>;
export const EditShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMetaDocument = gql`
    mutation EditShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMeta($input: AdyenPaymentUpsertStoreInput!) {
  onlinePaymentRoot {
    upsertAdyenPaymentStoreMeta(input: $input) {
      result
    }
  }
}
    `;
export type EditShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMetaMutationFn = Apollo.MutationFunction<EditShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMetaMutation, EditShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMetaMutationVariables>;

/**
 * __useEditShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMetaMutation__
 *
 * To run a mutation, you first call `useEditShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMetaMutation, { data, loading, error }] = useEditShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMetaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMetaMutation(baseOptions?: Apollo.MutationHookOptions<EditShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMetaMutation, EditShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMetaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMetaMutation, EditShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMetaMutationVariables>(EditShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMetaDocument, options);
      }
export type EditShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMetaMutationHookResult = ReturnType<typeof useEditShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMetaMutation>;
export type EditShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMetaMutationResult = Apollo.MutationResult<EditShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMetaMutation>;
export type EditShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMetaMutationOptions = Apollo.BaseMutationOptions<EditShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMetaMutation, EditShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMetaMutationVariables>;