import React, { memo } from "react";
import styled from "styled-components";
import { Collapse, Radio } from "antd";

import { withFormDependencies } from "components/antd/Form";
import { Spacer } from "components/Spacer";
import { colors } from "constants/colors";

import { MobileOrderDesignSettingsFormItem } from "..";

const LayoutWrapper = styled.div`
  display: flex;
`;

const Form = styled.form`
  flex: 1;
`;

const Required = styled.span`
  color: red;
`;

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;

const StyledRadio = styled(Radio)`
  line-height: 22px;
`;

const PreviewWrapper = styled.div`
  width: 300px;
`;

const StyledCollapse = styled(Collapse)`
  background-color: ${colors.Other.Header};
  border-radius: 0px;

  .ant-collapse-item {
    .ant-collapse-header {
      color: ${colors.Text.OnColor};
    }

    .ant-collapse-content {
      border-radius: 0px !important;

      .ant-collapse-content-box {
        padding: 0px;
      }
    }
  }
`;

export const MobileOrderThemeSettings = memo(() => (
  <>
    <Required>*</Required>
    <Spacer inline size={4} />
    <label htmlFor="themeSetting">背景色</label>

    <Spacer size={5} />

    <MobileOrderDesignSettingsFormItem name="themeSetting" required>
      <MobileOrderDesignSettingsFormItem.NonProperty
        noStyle
        shouldUpdate={withFormDependencies(({ themeSetting }) => [themeSetting])}
      >
        {({ getFieldsValue, setFieldValue }) => (
          <StyledRadioGroup
            name="themeSetting"
            value={getFieldsValue().themeSetting}
            onChange={(e) => setFieldValue("themeSetting", e.target.value)}
          >
            <StyledRadio value="dark">ダーク</StyledRadio>
            <Spacer size={5} />
            <StyledRadio value="light">ライト</StyledRadio>
          </StyledRadioGroup>
        )}
      </MobileOrderDesignSettingsFormItem.NonProperty>
    </MobileOrderDesignSettingsFormItem>
  </>
));
