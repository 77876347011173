import React, { memo } from "react";
import styled from "styled-components";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";
import { colors } from "constants/colors";

import { EditShopCashlessSettingStoreMetaFormItem } from "../useEditShopCashlessSettingStoreMetaForm";

type Props = Omit<FormItemProps, "children" | "name">;

const PrefixSpan = styled.span`
  font-size: 14px;
  padding: 4px 12px;
  margin-right: 12px;
  border-right: 1px solid ${colors.Border.Default};
  background-color: ${colors.BackGround.Tertiary};
`;

const StyledInput = styled(Input)`
  width: 100%;
  padding: 0;
  padding-right: 12px;
`;

export const TelephoneNumberField = memo<Props>((props) => (
  <EditShopCashlessSettingStoreMetaFormItem
    label="電話番号(ハイフン無し)、先頭0アリ"
    name="telephoneNumber"
    rules={[
      {
        required: true,
        pattern: /^0[0-9]{9,10}$/,
        message: "電話番号は半角数字で入力してください",
      },
    ]}
    {...props}
  >
    <StyledInput prefix={<PrefixSpan>+81</PrefixSpan>} placeholder="08012345678" />
  </EditShopCashlessSettingStoreMetaFormItem>
));
