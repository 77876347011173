import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditShopCashlessSettingStoreMetaFormItem } from "../useEditShopCashlessSettingStoreMetaForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const AreaField = memo<Props>((props) => (
  <EditShopCashlessSettingStoreMetaFormItem
    label="丁目以降（英語）"
    name="address2"
    rules={[
      {
        required: true,
        pattern: /^[a-zA-Z0-9!-/:-@[-`{-~ ]+$/,
        message: "丁目以降（英語）は半角英数字記号で入力してください",
      },
    ]}
    {...props}
  >
    {/* cspell:disable-next */}
    <Input placeholder="例: 1-1-1 dinii building 1F" />
  </EditShopCashlessSettingStoreMetaFormItem>
));
