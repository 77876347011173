import React, { memo } from "react";
import { CrownOutlined } from "@ant-design/icons";

import { CashlessSettingMenu } from "components/Layout/DashboardLayout/NavigationDrawer/CashlessSettingMenu";
import { MenuItem } from "components/Layout/DashboardLayout/NavigationDrawer/MenuItem";
import {
  SubMenu,
  SubMenuPropsWithoutFeatures,
} from "components/Layout/DashboardLayout/NavigationDrawer/SubMenu";
import { TablePrintingMenu } from "components/Layout/DashboardLayout/NavigationDrawer/TablePrintingMenu";
import { Company } from "components/Layout/DashboardLayout/types";

type Props = {
  company?: Company;
} & SubMenuPropsWithoutFeatures;

export const ServiceAdminMenu = memo<Props>(({ company, ...props }) => (
  <SubMenu
    key="serviceAdmin"
    icon={<CrownOutlined />}
    title="サービス管理"
    features="onlyServiceAdmin"
    {...props}
  >
    <MenuItem route="serviceAdmins" to="/serviceAdmin" text="サービス管理者" />
    <MenuItem route="corporations" to="/corporation" text="法人一覧" />
    <MenuItem route="companies" to="/company" text="業態一覧" />
    <MenuItem route="inputMasterData" to="/inputMasterData" text="マスターデータ一括投入" />
    <MenuItem route="inputShopData" to="/inputShopData" text="店舗データ一括投入" />
    <MenuItem route="copyMasterData" to="/copyMasterData" text="マスターデータコピー" />
    <MenuItem route="copyDealingData" to="/copyDealingData" text="取り扱いデータコピー" />
    <MenuItem route="qrCodes" to="/qrCode" text="QR コード一覧" />
    <MenuItem route="generateQrCodes" to="/qrCode/generate" text="QR コード生成" />
    <MenuItem route="onboardingHandout" to="/onboardingHandout" text="配布物" />
    <MenuItem route="convertTecFormat" to="/convertTecFormat" text="TEC フォーマット変換" />
    <MenuItem route="excludeFromAggregation" to="/excludeFromAggregation" text="集計除外設定" />
    <MenuItem route="gmoBankAccount" to="/gmoBankAccount" text="法人口座設定" />
    <CashlessSettingMenu />
    <TablePrintingMenu />
    <MenuItem route="editDefaultTaxMethods" to="/defaultTaxMethods/edit" text="税種別設定" />
    <MenuItem route="lineReportingImages" to="/lineReportingImages" text="AIくん手動画像作成" />
  </SubMenu>
));
