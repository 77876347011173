import React, { memo } from "react";
import styled from "styled-components";
import { Select } from "antd";
import { ADYEN_MERCHANT_ACCOUNT_IDS } from "models/adyenMerchantCategory";

import { FormItemProps } from "components/antd/Form";

import { AddShopCashlessSettingMerchantCategoryFormItem } from "../useAddShopCashlessSettingMerchantCategoryForm";

type Props = Omit<FormItemProps, "children" | "name"> & {
  disabled?: boolean;
};

const StyledSelect = styled(Select)`
  max-width: 320px;
`;

export const MerchantCategoryField = memo<Props>(({ disabled, ...props }) => (
  <AddShopCashlessSettingMerchantCategoryFormItem
    name="merchantCategory"
    rules={[{ required: true, message: "MCCを設定してください" }]}
    required
    {...props}
  >
    <StyledSelect disabled={disabled}>
      {ADYEN_MERCHANT_ACCOUNT_IDS.map((accountId) => (
        <Select.Option key={accountId} value={accountId}>
          {accountId}
        </Select.Option>
      ))}
    </StyledSelect>
  </AddShopCashlessSettingMerchantCategoryFormItem>
));
