import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AddServiceAdminFormItem } from "../useAddServiceAdminForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const EmailField = memo<Props>((props) => (
  <AddServiceAdminFormItem
    label="メールアドレス"
    name="email"
    rules={[
      { required: true, message: "メールアドレスを入力してください" },
      {
        pattern: new RegExp(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/),
        message: "メールアドレスの形式が正しくありません",
      },
    ]}
    {...props}
  >
    <Input />
  </AddServiceAdminFormItem>
));
