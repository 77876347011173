import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Alert } from "antd";
import { ApolloError } from "@apollo/client";
import { convertTelephoneNumber } from "models/adyenPaymentStoreMeta";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { ShopCashlessSettingHeader } from "components/PageHeader/ShopCashlessSettingHeader";

import { EditShopCashlessSettingStoreMetaFormValues } from "./EditShopCashlessSettingStoreMetaForm/useEditShopCashlessSettingStoreMetaForm";
import { EditShopCashlessSettingStoreMetaForm } from "./EditShopCashlessSettingStoreMetaForm";
import {
  useEditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMetaQuery,
  useEditShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMetaMutation,
} from "./queries";

export const EditShopCashlessSettingStoreMeta = () => {
  const { id } = useParams<{ id: string }>();

  const { data, error, loading } = useEditShopCashlessSettingStoreMetaGetAdyenPaymentStoreMetaQuery(
    id ? { variables: { shopId: id } } : { skip: true },
  );
  const shop = useMemo(() => data?.shop_by_pk ?? null, [data]);
  const adyenPaymentStoreMeta = useMemo(() => data?.adyenPaymentStoreMeta[0] ?? null, [data]);

  const [upsertAdyenPaymentStoreMetaMutation, { loading: loadingUpsertAdyenPaymentStoreMeta }] =
    useEditShopCashlessSettingStoreMetaUpsertAdyenPaymentStoreMetaMutation();

  const onSubmit = useCallback(
    async (values: EditShopCashlessSettingStoreMetaFormValues) => {
      if (!id) return;

      const telephoneNumber = convertTelephoneNumber(values.telephoneNumber);
      const input = { ...values, shopId: id, telephoneNumber };
      try {
        await upsertAdyenPaymentStoreMetaMutation({
          variables: {
            input,
          },
        });
        message.success("更新に成功しました");
      } catch (e) {
        if (e instanceof ApolloError) {
          message.error(e.message);
          return;
        }
        message.error("更新に失敗しました");
      }
    },
    [id, upsertAdyenPaymentStoreMetaMutation],
  );

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  return (
    <DashboardLayout
      title={shop?.name ?? ""}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "決済店舗" }],
      }}
    >
      <ShopCashlessSettingHeader shop={shop} />
      {loading && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      {shop && (
        <EditShopCashlessSettingStoreMetaForm
          adyenPaymentStoreMeta={adyenPaymentStoreMeta}
          loading={loadingUpsertAdyenPaymentStoreMeta}
          onSubmit={onSubmit}
          onFormValidationError={onFormValidationError}
        />
      )}
    </DashboardLayout>
  );
};
