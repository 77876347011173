import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const QuestionnaireAnalyticsCommentsMetricsGetShops = gql`
    query QuestionnaireAnalyticsCommentsMetricsGetShops($corporationId: uuid!, $questionnaireId: uuid!) {
  corporation(where: {id: {_eq: $corporationId}}) {
    id
    name
    companies(
      where: {corporationId: {_eq: $corporationId}, questionnaireConfigs: {questionnaireId: {_eq: $questionnaireId}}, archivedAt: {_is_null: true}}
    ) {
      id
      name
      shops(where: {archivedAt: {_is_null: true}}) {
        shopId
        name
      }
    }
  }
}
    `;
export const QuestionnaireAnalyticsCommentsMetricsGetQuestionnaire = gql`
    query QuestionnaireAnalyticsCommentsMetricsGetQuestionnaire($corporationId: uuid!) {
  questionnaire(
    where: {questionnaireConfigs: {company: {corporationId: {_eq: $corporationId}, archivedAt: {_is_null: true}}}}
    limit: 1
  ) {
    id
  }
}
    `;
export const QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireComments = gql`
    query QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireComments($input: QuestionnaireCommentsInput!) {
  questionnaireComments(input: $input) {
    questionnaireAnswerId
    customerQuestionnaireId
    shopName
    score
    category
    title
    text
    gender
    age
    visitedAt
    checkedInCount
    lineId
  }
}
    `;
export const QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCount = gql`
    query QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCount($input: QuestionnaireCommentsInput!) {
  questionnaireCommentsCount(input: $input) {
    totalCommentsCount
  }
}
    `;
export type QuestionnaireAnalyticsCommentsMetricsGetShopsQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
  questionnaireId: Types.Scalars['uuid'];
}>;


export type QuestionnaireAnalyticsCommentsMetricsGetShopsQuery = (
  { __typename?: 'query_root' }
  & { corporation: Array<(
    { __typename?: 'corporation' }
    & Pick<Types.Corporation, 'id' | 'name'>
    & { companies: Array<(
      { __typename?: 'company' }
      & Pick<Types.Company, 'id' | 'name'>
      & { shops: Array<(
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      )> }
    )> }
  )> }
);

export type QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
}>;


export type QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireQuery = (
  { __typename?: 'query_root' }
  & { questionnaire: Array<(
    { __typename?: 'questionnaire' }
    & Pick<Types.Questionnaire, 'id'>
  )> }
);

export type QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsQueryVariables = Types.Exact<{
  input: Types.QuestionnaireCommentsInput;
}>;


export type QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsQuery = (
  { __typename?: 'query_root' }
  & { questionnaireComments: Array<(
    { __typename?: 'QuestionnaireComment' }
    & Pick<Types.QuestionnaireComment, 'questionnaireAnswerId' | 'customerQuestionnaireId' | 'shopName' | 'score' | 'category' | 'title' | 'text' | 'gender' | 'age' | 'visitedAt' | 'checkedInCount' | 'lineId'>
  )> }
);

export type QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCountQueryVariables = Types.Exact<{
  input: Types.QuestionnaireCommentsInput;
}>;


export type QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCountQuery = (
  { __typename?: 'query_root' }
  & { questionnaireCommentsCount: (
    { __typename?: 'QuestionnaireCommentCountOutput' }
    & Pick<Types.QuestionnaireCommentCountOutput, 'totalCommentsCount'>
  ) }
);


export const QuestionnaireAnalyticsCommentsMetricsGetShopsDocument = gql`
    query QuestionnaireAnalyticsCommentsMetricsGetShops($corporationId: uuid!, $questionnaireId: uuid!) {
  corporation(where: {id: {_eq: $corporationId}}) {
    id
    name
    companies(
      where: {corporationId: {_eq: $corporationId}, questionnaireConfigs: {questionnaireId: {_eq: $questionnaireId}}, archivedAt: {_is_null: true}}
    ) {
      id
      name
      shops(where: {archivedAt: {_is_null: true}}) {
        shopId
        name
      }
    }
  }
}
    `;

/**
 * __useQuestionnaireAnalyticsCommentsMetricsGetShopsQuery__
 *
 * To run a query within a React component, call `useQuestionnaireAnalyticsCommentsMetricsGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireAnalyticsCommentsMetricsGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireAnalyticsCommentsMetricsGetShopsQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *      questionnaireId: // value for 'questionnaireId'
 *   },
 * });
 */
export function useQuestionnaireAnalyticsCommentsMetricsGetShopsQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireAnalyticsCommentsMetricsGetShopsQuery, QuestionnaireAnalyticsCommentsMetricsGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireAnalyticsCommentsMetricsGetShopsQuery, QuestionnaireAnalyticsCommentsMetricsGetShopsQueryVariables>(QuestionnaireAnalyticsCommentsMetricsGetShopsDocument, options);
      }
export function useQuestionnaireAnalyticsCommentsMetricsGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireAnalyticsCommentsMetricsGetShopsQuery, QuestionnaireAnalyticsCommentsMetricsGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireAnalyticsCommentsMetricsGetShopsQuery, QuestionnaireAnalyticsCommentsMetricsGetShopsQueryVariables>(QuestionnaireAnalyticsCommentsMetricsGetShopsDocument, options);
        }
export type QuestionnaireAnalyticsCommentsMetricsGetShopsQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsCommentsMetricsGetShopsQuery>;
export type QuestionnaireAnalyticsCommentsMetricsGetShopsLazyQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsCommentsMetricsGetShopsLazyQuery>;
export type QuestionnaireAnalyticsCommentsMetricsGetShopsQueryResult = Apollo.QueryResult<QuestionnaireAnalyticsCommentsMetricsGetShopsQuery, QuestionnaireAnalyticsCommentsMetricsGetShopsQueryVariables>;
export const QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireDocument = gql`
    query QuestionnaireAnalyticsCommentsMetricsGetQuestionnaire($corporationId: uuid!) {
  questionnaire(
    where: {questionnaireConfigs: {company: {corporationId: {_eq: $corporationId}, archivedAt: {_is_null: true}}}}
    limit: 1
  ) {
    id
  }
}
    `;

/**
 * __useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireQuery__
 *
 * To run a query within a React component, call `useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *   },
 * });
 */
export function useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireQuery, QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireQuery, QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireQueryVariables>(QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireDocument, options);
      }
export function useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireQuery, QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireQuery, QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireQueryVariables>(QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireDocument, options);
        }
export type QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireQuery>;
export type QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireLazyQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireLazyQuery>;
export type QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireQueryResult = Apollo.QueryResult<QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireQuery, QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireQueryVariables>;
export const QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsDocument = gql`
    query QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireComments($input: QuestionnaireCommentsInput!) {
  questionnaireComments(input: $input) {
    questionnaireAnswerId
    customerQuestionnaireId
    shopName
    score
    category
    title
    text
    gender
    age
    visitedAt
    checkedInCount
    lineId
  }
}
    `;

/**
 * __useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsQuery__
 *
 * To run a query within a React component, call `useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsQuery, QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsQuery, QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsQueryVariables>(QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsDocument, options);
      }
export function useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsQuery, QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsQuery, QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsQueryVariables>(QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsDocument, options);
        }
export type QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsQuery>;
export type QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsLazyQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsLazyQuery>;
export type QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsQueryResult = Apollo.QueryResult<QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsQuery, QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsQueryVariables>;
export const QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCountDocument = gql`
    query QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCount($input: QuestionnaireCommentsInput!) {
  questionnaireCommentsCount(input: $input) {
    totalCommentsCount
  }
}
    `;

/**
 * __useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCountQuery__
 *
 * To run a query within a React component, call `useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCountQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCountQuery, QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCountQuery, QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCountQueryVariables>(QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCountDocument, options);
      }
export function useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCountQuery, QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCountQuery, QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCountQueryVariables>(QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCountDocument, options);
        }
export type QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCountQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCountQuery>;
export type QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCountLazyQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCountLazyQuery>;
export type QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCountQueryResult = Apollo.QueryResult<QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCountQuery, QuestionnaireAnalyticsCommentsMetricsGetQuestionnaireCommentsCountQueryVariables>;