import React, { memo, useCallback, useState } from "react";
import { Button, Col, Row } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { FormHelp } from "components/Form/FormHelp";
import { ImageField } from "components/Form/ImageField";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { AutoResetMenuSoldOutField } from "pages/EditShop/EditShopForm/AutoResetMenuSoldOutField";
import { ChangeDateTimeField } from "pages/EditShop/EditShopForm/ChangeDateTimeField";
import { CloseField } from "pages/EditShop/EditShopForm/CloseField";
import { EnableAlertField } from "pages/EditShop/EditShopForm/EnableAlertField";
import { NameField } from "pages/EditShop/EditShopForm/NameField";
import { OneOrderLimitPerPeopleCountField } from "pages/EditShop/EditShopForm/OneOrderLimitPerPeopleCountField";
import { OneOrderLimitPerPeopleField } from "pages/EditShop/EditShopForm/OneOrderLimitPerPeopleField";
import { OpenField } from "pages/EditShop/EditShopForm/OpenField";
import { useEditShopForm } from "pages/EditShop/EditShopForm/useEditShopForm";
import { Shop } from "pages/EditShop/types";
import { ShopSetInput } from "types/graphql";

import { AccountingActionTypeField } from "./AccountingActionTypeField";
import { CheckInElapsedTimeAlertThresholdMinutesField } from "./CheckInElapsedTimeAlertThresholdMinutesField";
import { EnableCallClerkField } from "./EnableCallClerkField";
import { EnableCustomerSegmentSelectionField } from "./EnableCustomerSegmentSelectionField";
import { EnableDisplayTaxExcludedPriceForCustomer } from "./EnableDisplayTaxExcludedPriceForCustomer";
import { EnableOfflinePayment } from "./EnableOfflinePayment";
import { EnableRemarkField } from "./EnableRemarkField";
import { LastOrderTextField } from "./LastOrderTextField";
import { LatestOrderElapsedTimeAlertThresholdMinutesField } from "./LatestOrderElapsedTimeAlertThresholdMinutesField";
import { OfflinePaymentDescriptionField } from "./OfflinePaymentDescriptionField";
import { UseKdField } from "./UseKdField";

type Props = {
  shop: Shop;
  onSubmit: (shop: ShopSetInput) => void;
  onClose: () => void;
  loading: boolean;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
};

export const EditShopForm = memo<Props>(
  ({ shop, onSubmit, onClose, loading, onFormValidationError }) => {
    const { isFeatureEnabled } = useIsFeatureEnabled();

    const { form, initialValues, submit } = useEditShopForm(shop, onSubmit);
    const [uploadImage, setUploadImage] = useState(initialValues.iconImageUrl ?? null);

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit({ iconImageUrl: uploadImage });
      } catch (e) {
        if (isValidateErrorEntity(e)) {
          onFormValidationError({ formValidationError: e });
        }
      }
    }, [form, submit, onFormValidationError, uploadImage]);

    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    return (
      <>
        <FormContent>
          <Form name="shop" form={form} layout="vertical" initialValues={initialValues}>
            <NameField wrapperCol={{ span: 8 }} />
            <ImageField
              image={uploadImage}
              setUploadImage={(image) => setUploadImage(image)}
              uploadImageApiKey="shopIconImage"
              formName="shop"
              label="アイコン画像"
              helpText={
                <p>
                  アイコン画像を設定すると以下の箇所に表示されます
                  <ul>
                    <li>チェックイン画面</li>
                    <li>おすすめ機能の見出し</li>
                  </ul>
                </p>
              }
            />
            <LastOrderTextField wrapperCol={{ span: 8 }} />
            <OfflinePaymentDescriptionField wrapperCol={{ span: 8 }} />
            <Row>
              <Col span={12}>
                <OpenField />
              </Col>
              <Col span={12}>
                <CloseField />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <OneOrderLimitPerPeopleField />
              </Col>
              <Col span={12}>
                <OneOrderLimitPerPeopleCountField />
              </Col>
            </Row>
            <Row>
              {isFeatureEnabled("showChangeDateTimeField") && (
                <Col span={12}>
                  <ChangeDateTimeField />
                </Col>
              )}
              <Col span={12}>
                <AutoResetMenuSoldOutField />
              </Col>
            </Row>
            <Row>
              {isFeatureEnabled("showEnableAlertField") && (
                <Col span={8}>
                  <EnableAlertField />
                </Col>
              )}
              {isFeatureEnabled("showEnableCustomerSegmentSelection") && (
                <Col span={8}>
                  <EnableCustomerSegmentSelectionField />
                </Col>
              )}
              {isFeatureEnabled("showEnableRemarkField") && (
                <Col span={8}>
                  <EnableRemarkField />
                </Col>
              )}
              {isFeatureEnabled("showDisableCallClerkField") && (
                <Col span={8}>
                  <EnableCallClerkField />
                </Col>
              )}
              {isFeatureEnabled("showEnableOfflinePayment") && (
                <Col span={8}>
                  <EnableOfflinePayment />
                </Col>
              )}
              {isFeatureEnabled("showUseKdField") && (
                <Col span={8}>
                  <UseKdField />
                </Col>
              )}
              <Col span={8}>
                <EnableDisplayTaxExcludedPriceForCustomer />
              </Col>
            </Row>
            <AccountingActionTypeField wrapperCol={{ span: 12 }} />
            {isFeatureEnabled("showTimeAlertThresholdFields") && (
              <Col>
                <FormHelp
                  label="レジ・ハンディのアラート設定"
                  help="レジ・ハンディのテーブル情報について、設定した経過時間を過ぎるとアラート状態になり、経過分数が赤文字で表示されます"
                />
                <Spacer size={4} />
                <Row>
                  <Col span={12}>
                    <CheckInElapsedTimeAlertThresholdMinutesField />
                  </Col>
                  <Col span={12}>
                    <LatestOrderElapsedTimeAlertThresholdMinutesField />
                  </Col>
                </Row>
              </Col>
            )}
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
