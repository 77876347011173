import React, { memo } from "react";

import { MenuItem } from "components/Layout/DashboardLayout/NavigationDrawer/MenuItem";
import {
  SubMenu,
  SubMenuPropsWithoutFeatures,
} from "components/Layout/DashboardLayout/NavigationDrawer/SubMenu";

export const CashlessSettingMenu = memo<SubMenuPropsWithoutFeatures>((props) => (
  <SubMenu
    key="cashlessSetting"
    title="ダイニーキャッシュレス設定"
    features="onlyServiceAdmin"
    {...props}
  >
    <MenuItem route="shopCashlessSetting" to="/cashlessSetting/shop" text="決済店舗" />
    <MenuItem
      route="bankAccountCashlessSetting"
      to="/cashlessSetting/bankAccount"
      text="銀行口座設定"
    />
  </SubMenu>
));
