import React, { memo } from "react";
import styled from "styled-components";
import { List } from "antd";
import { PaginationConfig } from "antd/es/pagination";

import { Pagination } from "hooks/usePagination";
import { ListItem } from "pages/QuestionnaireAnalyticsCommentsMetrics/CommentList/ListItem";
import { QuestionCategoryType } from "types/graphql";

import { FilterConditions } from "../CommentFilter";
import { QuestionnaireComment } from "../types";

import { HeaderMenu } from "./HeaderMenu";

const Container = styled.div`
  margin-bottom: 32px;
  .ant-list-items,
  .ant-list-empty-text {
    background-color: #ffffff;
  }
`;

type Props = {
  questionnaireComments: QuestionnaireComment[];
  totalCommentCount: number;
  category?: QuestionCategoryType;
  loading: boolean;
  filterConditions: Partial<FilterConditions>;
  pagination: Pagination;
  setPagination: ({ pageSize, current }: PaginationConfig) => void;
};

export const CommentList = memo<Props>(
  ({
    questionnaireComments,
    totalCommentCount,
    category,
    loading,
    filterConditions,
    pagination,
    setPagination,
  }) => (
    <Container>
      <HeaderMenu
        category={category}
        filterConditions={filterConditions}
        setPagination={setPagination}
      />
      <List
        dataSource={questionnaireComments}
        loading={loading}
        pagination={{
          ...pagination,
          total: totalCommentCount,
          onChange: (page, pageSize) => setPagination({ current: page, pageSize }),
          defaultPageSize: 10,
        }}
        renderItem={(comment, index) => <ListItem key={index} comment={comment} />}
      />
    </Container>
  ),
);
