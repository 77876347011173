import React, { useMemo } from "react";
import { Alert } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";

import { useShopCashlessSettingGetShopQuery } from "./queries";
import { ShopTable } from "./ShopTable";

export const ShopCashlessSetting = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getShopsData,
    loading: loadingShops,
    error: getShopsDataError,
  } = useShopCashlessSettingGetShopQuery(companyId ? { variables: { companyId } } : { skip: true });
  const shops = useMemo(() => getShopsData?.shop ?? [], [getShopsData]);

  return (
    <DashboardLayout title="決済店舗">
      <PageHeader title="決済店舗" />

      {getShopsDataError && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <ShopTable shops={shops} loading={loadingShops} />
    </DashboardLayout>
  );
};
