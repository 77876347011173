import dayjs from "dayjs";

import { SalesAnalyticsGetMonthlySalesBudgetQuery } from "./queries";

export enum ReportByType {
  month = "month",
  day = "day",
  dayOfWeek = "dayOfWeek",
  hour = "hour",
  businessOperationHourType = "businessOperationHourType",
}

export type NormalizedSalesAnalyticsRow = {
  isEmpty: false;
  isSummaryRow: boolean;
  name: string;
  shopId: string;
  shopName: string;
  businessDaysCount: number | null;
  businessOperationHourLabel?: string;
  totalAmount: number;
  totalTakeOutAmount: number;
  totalEatInAmount: number;
  previousMonthTotalAmount: number | null;
  previousYearTotalAmount: number | null;
  previousMonthSameDowTotalAmount: number | null;
  previousYearSameDowTotalAmount: number | null;
  totalCostAmount: number | null;
  grossProfitAmount: number;
  repeaterTableTotalAmount: number;
  takeoutTotalAmount: number;
  dinnerTotalAmount: number;
  lunchTotalAmount: number;
  faveYellTotalAmount: number;
  drinkTotalAmount: number;
  foodTotalAmount: number;
  otherTotalAmount: number;
  planTotalAmount: number;
  percentageOfLastYearTotalAmount: number;
  salesPerCustomerAmount: number;
  salesTargetAmount: number | null;
  customerCount: number;
  numPeople: number;
  notCheckedInNumPeople: number;
  newCustomerCount: number;
  repeatVisitCustomerCount: number;
  mobileOrderPercentage: number;
  goalCompletionPercentage: number | null;
  goalDifference: number | null;
  previousYearComparisonPercentage: number;
  previousMonthComparisonPercentage: number;
  previousMonthSameDowPercentage: number;
  previousYearSameDowPercentage: number;
  costPercentage: number | null;
  groupCount: number;
  checkedInGroupCount: number;
  groupCheckInPercentage: number;
  customerCheckInPercentage: number;
  repeaterPercentage: number;
  repeaterSalesPercentage: number;
  salesPerCustomer: number;
  lunchCustomerCount: number;
  lunchSalesPerCustomer: number;
  dinnerCustomerCount: number;
  dinnerSalesPerCustomer: number;
  eatInNumPeople: number;
  eatInSalesPerCustomer: number;
  takeOutNumPeople: number;
  takeOutSalesPerCustomer: number;
  reservationGroupCount: number;
  reservationCustomerCount: number;
  reservationTotalAmount: number;
  grossProfitPercentage: number | null;
  ambassadorCount: number;
  introducedCustomerCount: number;
};

export type EmptySalesAnalyticsRow = {
  name: string;
  shopId: string;
  shopName: string;
  isEmpty: true;
  businessOperationHourLabel?: undefined;
  salesTargetAmount: number | null;
};

export type SalesAnalyticsRow = NormalizedSalesAnalyticsRow | EmptySalesAnalyticsRow;

export type QueryConditions = {
  shopIds: string[];
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
};

export type MonthlySalesBudget =
  SalesAnalyticsGetMonthlySalesBudgetQuery["monthlySalesBudget"][number];
