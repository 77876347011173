import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditShopCashlessSettingStoreMetaFormItem } from "../useEditShopCashlessSettingStoreMetaForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const CityWardField = memo<Props>((props) => (
  <EditShopCashlessSettingStoreMetaFormItem
    label="市区(英語)"
    name="address3"
    rules={[
      {
        required: true,
        pattern: /^[a-zA-Z!-/:-@[-`{-~ ]+$/,
        message: "市区(英語)は半角英字記号で入力してください",
      },
    ]}
    {...props}
  >
    {/* cspell:disable-next */}
    <Input placeholder="例: Kawasaki-shi Nakahara-ku" />
  </EditShopCashlessSettingStoreMetaFormItem>
));
