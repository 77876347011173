import { useCallback, useEffect, useMemo } from "react";
import { revertTelephoneNumber } from "models/adyenPaymentStoreMeta";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isValidateErrorEntity } from "util/validation";

import { createFormItem, Form } from "components/antd/Form";

import { AdyenPaymentStoreMeta } from "../types";

export type EditShopCashlessSettingStoreMetaFormValues = Pick<
  AdyenPaymentStoreMeta,
  "shopNameEn" | "telephoneNumber" | "postalCode" | "city" | "address1" | "address2" | "address3"
>;

export const EditShopCashlessSettingStoreMetaFormItem =
  createFormItem<EditShopCashlessSettingStoreMetaFormValues>();

export const useEditShopCashlessSettingStoreMetaForm = ({
  adyenPaymentStoreMeta,
  onSubmit,
  onFormValidationError,
}: {
  adyenPaymentStoreMeta: AdyenPaymentStoreMeta | null;
  onSubmit: (values: EditShopCashlessSettingStoreMetaFormValues) => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
}) => {
  const [form] = Form.useForm<EditShopCashlessSettingStoreMetaFormValues>();

  const initialValues = useMemo<EditShopCashlessSettingStoreMetaFormValues>(
    () => ({
      shopNameEn: adyenPaymentStoreMeta?.shopNameEn ?? "",
      telephoneNumber: adyenPaymentStoreMeta?.telephoneNumber
        ? revertTelephoneNumber(adyenPaymentStoreMeta.telephoneNumber)
        : "",
      postalCode: adyenPaymentStoreMeta?.postalCode ?? "",
      city: adyenPaymentStoreMeta?.city ?? "",
      address1: adyenPaymentStoreMeta?.address1 ?? "",
      address2: adyenPaymentStoreMeta?.address2 ?? "",
      address3: adyenPaymentStoreMeta?.address3 ?? "",
    }),
    [adyenPaymentStoreMeta],
  );

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const submit = useCallback(async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      onSubmit(values);
    } catch (e) {
      if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
    }
  }, [form, onFormValidationError, onSubmit]);

  return { form, initialValues, submit };
};
