import React, { memo } from "react";
import { Link } from "react-router-dom";

import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";

import { Shop } from "../types";

type Props = {
  loading?: boolean;
  shops: Shop[];
};

export const ShopTable = memo<Props>(({ loading, shops }) => {
  const [pagination, setPagination] = usePagination();

  const columns = [
    {
      title: "店舗名",
      render: (_: unknown, { shopId, name }: Shop) => (
        <Link to={`/cashlessSetting/shop/${shopId}/storeMeta/edit`}>{name}</Link>
      ),
    } as const,
    {
      title: "店舗詳細",
      width: 100,
      align: "center",
      render: () => null, // TODO: 別チケットで実装
    } as const,
    {
      title: "MCC",
      width: 100,
      align: "center",
      render: () => null, // TODO: 別チケットで実装
    } as const,
    {
      title: "TerminalId",
      width: 100,
      align: "center",
      render: () => null, // TODO: 別チケットで実装
    } as const,
    {
      title: "料率",
      width: 100,
      align: "center",
      render: () => null, // TODO: 別チケットで実装
    } as const,
    {
      title: "銀行口座",
      width: 100,
      align: "center",
      render: () => null, // TODO: 別チケットで実装
    } as const,
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={shops}
      loading={loading}
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
