import axios, { AxiosRequestConfig } from "axios";
import dayjs from "dayjs";
import { CsvName } from "models/csvName";
import { MasterCsvError, ValidateMenuResult, ValidateOptionResult } from "models/menuMasterCsv";
import { stringify } from "query-string";

import { UnreachableError } from "libs/unreachable";
import { TecAggregationIntegrationTypeEnum } from "types/graphql";

import { progressStreamFetch } from "./progressStreamFetch";

const client = axios.create({ baseURL: import.meta.env.API_BASE_URL });
const onlinePaymentClient = axios.create({ baseURL: import.meta.env.ONLINE_PAYMENT_API_BASE_URL });

export type Context = {
  authKey: string;
  corporationId: string;
  companyId: string;
  _companyId: number;
  shopId?: string;
};

const mergeCustomHeaders = (config: AxiosRequestConfig, headers: Record<string, string>) => ({
  ...config,
  headers: { ...headers, ...config.headers },
});

const getCustomerHeaders = (context: Context) => ({
  "x-auth-key": context.authKey,
  "x-corporation-id": context.corporationId,
  "x-company-uuid": context.companyId,
  "x-shop-id": context.shopId ?? "",
});

const getRequestConfig = (context: Context, config: AxiosRequestConfig) =>
  mergeCustomHeaders(config, getCustomerHeaders(context));

type UploadPlanImageData = FormData;

type UploadPlanImageResponse = { url: string };

const uploadPlanImage = (
  context: Context,
  data: UploadPlanImageData,
  config: AxiosRequestConfig = {},
) =>
  client.post<UploadPlanImageResponse>(
    "/shop_admin_app/plans/upload_image",
    data,
    getRequestConfig(
      context,
      mergeCustomHeaders(config, { "content-type": "multipart/form-data" }),
    ),
  );

type UploadMobileOrderWelcomeScreenSettingImageData = FormData;
type UploadMobileOrderWelcomeScreenSettingImageResponse = { url: string };

const uploadMobileOrderWelcomeScreenSettingImage = (
  context: Context,
  data: UploadMobileOrderWelcomeScreenSettingImageData,
  config: AxiosRequestConfig = {},
) =>
  client.post<UploadMobileOrderWelcomeScreenSettingImageResponse>(
    "/shop_admin_app/mobile-order-welcome-screen-settings/upload_image",
    data,
    getRequestConfig(
      context,
      mergeCustomHeaders(config, { "content-type": "multipart/form-data" }),
    ),
  );

type UploadMenuImageData = FormData;

type UploadMenuImageResponse = { url: string };

const uploadMenuImage = (
  context: Context,
  data: UploadMenuImageData,
  config: AxiosRequestConfig = {},
) =>
  client.post<UploadMenuImageResponse>(
    "/shop_admin_app/menus/upload_image",
    data,
    getRequestConfig(
      context,
      mergeCustomHeaders(config, { "content-type": "multipart/form-data" }),
    ),
  );

type UploadChoiceImageData = FormData;

type UploadChoiceImageResponse = { url: string };

const uploadChoiceImage = (
  context: Context,
  data: UploadChoiceImageData,
  config: AxiosRequestConfig = {},
) =>
  client.post<UploadChoiceImageResponse>(
    "/shop_admin_app/choices/upload_image",
    data,
    getRequestConfig(
      context,
      mergeCustomHeaders(config, { "content-type": "multipart/form-data" }),
    ),
  );

type UploadPlanChoiceImageData = FormData;

type UploadPlanChoiceImageResponse = { url: string };

const uploadPlanChoiceImage = (
  context: Context,
  data: UploadPlanChoiceImageData,
  config: AxiosRequestConfig = {},
) =>
  client.post<UploadPlanChoiceImageResponse>(
    "/shop_admin_app/plan_choices/upload_image",
    data,
    getRequestConfig(
      context,
      mergeCustomHeaders(config, { "content-type": "multipart/form-data" }),
    ),
  );

type UploadCouponImageData = FormData;

type UploadCouponImageResponse = { url: string };

const uploadCouponImage = (
  context: Context,
  data: UploadCouponImageData,
  config: AxiosRequestConfig = {},
) =>
  client.post<UploadCouponImageResponse>(
    "/shop_admin_app/coupons/upload_image",
    data,
    getRequestConfig(
      context,
      mergeCustomHeaders(config, { "content-type": "multipart/form-data" }),
    ),
  );

type UploadAccountingSlipImageData = FormData;

type UploadAccountingSlipImageResponse = { url: string };

const uploadAccountingSlipImage = (
  context: Context,
  data: UploadAccountingSlipImageData,
  config: AxiosRequestConfig = {},
) =>
  client.post<UploadAccountingSlipImageResponse>(
    "/shop_admin_app/accounting_slip_image/upload_image",
    data,
    getRequestConfig(
      context,
      mergeCustomHeaders(config, { "content-type": "multipart/form-data" }),
    ),
  );

type UploadCategoryImageData = FormData;

type UploadCategoryImageResponse = { url: string };

const uploadCategoryImage = (
  context: Context,
  data: UploadCategoryImageData,
  config: AxiosRequestConfig = {},
) =>
  client.post<UploadCategoryImageResponse>(
    "/shop_admin_app/categories/upload_image",
    data,
    getRequestConfig(
      context,
      mergeCustomHeaders(config, { "content-type": "multipart/form-data" }),
    ),
  );

type UploadMenuMasterCsvData = FormData;

type VerifyMenuMasterCsv = {
  addingMenus?: ValidateMenuResult["addingMenus"];
  updatingMenus?: ValidateMenuResult["updatingMenus"];
  deletingMenus?: ValidateMenuResult["deletingMenus"];
  addingOptions?: ValidateOptionResult["addingOptions"];
  updatingOptions?: ValidateOptionResult["updatingOptions"];
  deletingOptions?: ValidateOptionResult["deletingOptions"];
  errors: MasterCsvError[];
};

type UploadMenuMasterCsv =
  | {
      menu: {
        row: number;
        isSucceeded: boolean;
      }[];
      errors: never;
    }
  | {
      errors: MasterCsvError[];
      menu: never;
    };

type RowSuccessResult = {
  isSucceeded: boolean;
  csvRowNumber: number;
};

type MenuMasterCsvResponse = {
  verificationResult: VerifyMenuMasterCsv;
  updateResult: UploadMenuMasterCsv | null;
};

export type MenuMasterUpdateResult = {
  menuResult: {
    rowResults: RowSuccessResult[];
    isSucceeded: boolean;
  };
  optionResult: {
    rowResults: RowSuccessResult[];
    isSucceeded: boolean;
  };
};

const verifyMenuMasterCsv = async ({
  context,
  data,
  onError,
  onProgress,
}: {
  context: Context;
  data: UploadMenuMasterCsvData;
  onError: (error: { message: string }) => void;
  onProgress: (progress: { percentage: number }) => void;
}) =>
  progressStreamFetch<MenuMasterCsvResponse>({
    url: import.meta.env.API_BASE_URL + "shop_admin_app/menu_master/verify_from_master_csv",
    init: {
      method: "POST",
      body: data,
      headers: getCustomerHeaders(context),
    },
    onError,
    onProgress,
  });

const uploadMenuMasterCsv = ({
  context,
  data,
  onError,
  onProgress,
}: {
  context: Context;
  data: UploadMenuMasterCsvData;
  onError: (error: { message: string }) => void;
  onProgress: (progress: { percentage: number }) => void;
}) =>
  progressStreamFetch<MenuMasterUpdateResult | null>({
    url: import.meta.env.API_BASE_URL + "shop_admin_app/menu_master/update_from_master_csv",
    init: {
      method: "POST",
      body: data,
      headers: getCustomerHeaders(context),
    },
    onError,
    onProgress,
  });

const downloadMenuMasterCsv = ({
  companyId,
  shopIds,
  context,
  config = {},
}: {
  companyId: string;
  shopIds: string[];
  context: Context;
  config?: AxiosRequestConfig;
}) =>
  client.post(
    `/shop_admin_app/menu_master/menu_csv`,
    {
      companyId,
      shopIds,
    },
    {
      ...getRequestConfig(context, mergeCustomHeaders(config, {})),
      // NOTE: BOM付きのレスポンスを受け取るために必要
      responseType: "blob",
    },
  );

const downloadMenuMasterOptionCsv = (
  companyId: string,
  context: Context,
  config: AxiosRequestConfig = {},
) =>
  client.get(`/shop_admin_app/menu_master/option_csv?companyId=${companyId}`, {
    ...getRequestConfig(context, mergeCustomHeaders(config, {})),
    // BOM付きのレスポンスを受け取るために必要
    responseType: "blob",
  });

const generateMonthlyReport = ({
  shopId,
  targetDate,
  context,
  config = {},
}: {
  shopId: string;
  targetDate: string;
  context: Context;
  config: AxiosRequestConfig;
}) =>
  client.post<{ imageUrl: string }>(
    "/line_reporting_manual/generate_monthly_report_image",
    {
      shopId,
      targetDate,
    },
    getRequestConfig(context, mergeCustomHeaders(config, {})),
  );

const generateWeeklyReport = ({
  shopId,
  targetDate,
  context,
  config = {},
}: {
  shopId: string;
  targetDate: string;
  context: Context;
  config: AxiosRequestConfig;
}) =>
  client.post<{ imageUrl: string }>(
    "/line_reporting_manual/generate_weekly_report_image",
    {
      shopId,
      targetDate,
    },
    getRequestConfig(context, mergeCustomHeaders(config, {})),
  );

const generateDailyReport = ({
  shopId,
  targetDate,
  context,
  config = {},
}: {
  shopId: string;
  targetDate: string;
  context: Context;
  config: AxiosRequestConfig;
}) =>
  client.post<{ imageUrl: string }>(
    "/line_reporting_manual/generate_daily_report_image",
    {
      shopId,
      targetDate,
    },
    getRequestConfig(context, mergeCustomHeaders(config, {})),
  );

type UploadMessageImageData = FormData;

type UploadMessageImageResponse = { url: string };

const uploadMessageImage = (
  context: Context,
  data: UploadMessageImageData,
  config: AxiosRequestConfig = {},
) =>
  client.post<UploadMessageImageResponse>(
    "/shop_admin_app/messages/upload_image",
    data,
    getRequestConfig(
      context,
      mergeCustomHeaders(config, { "content-type": "multipart/form-data" }),
    ),
  );

type UploadShopIconImageData = FormData;

type UploadShopIconImageResponse = { url: string };

const uploadShopIconImage = (
  context: Context,
  data: UploadShopIconImageData,
  config: AxiosRequestConfig = {},
) =>
  client.post<UploadShopIconImageResponse>(
    "/shop_admin_app/shop-icons/upload_image",
    data,
    getRequestConfig(
      context,
      mergeCustomHeaders(config, { "content-type": "multipart/form-data" }),
    ),
  );

type DownloadRangeAggregatedDataParams = {
  start: {
    year: number;
    month: number;
    date: number;
  };
  end: {
    year: number;
    month: number;
    date: number;
  };
  shopId: string;
  csvNames?: CsvName[];
};

const downloadRangeAggregatedData = (
  context: Context,
  { start, end, shopId, csvNames }: DownloadRangeAggregatedDataParams,
) => {
  const startDateString = `${start.year}${String(start.month).padStart(2, "0")}${String(
    start.date,
  ).padStart(2, "0")}`;
  const endDateString = `${end.year}${String(end.month).padStart(2, "0")}${String(
    end.date,
  ).padStart(2, "0")}`;

  return client.post(
    `/shop_admin_app/export_aggregation_csv/query?${stringify({
      start: startDateString,
      end: endDateString,
    })}`,
    {
      start: startDateString,
      end: endDateString,
      shopId,
      csvNames,
    },
    getRequestConfig(context, { responseType: "blob" }),
  );
};

const downloadNikkeiAggregationCsvByShopsData = (
  context: Context,
  { shopIds, targetDate }: { shopIds: string[]; targetDate: string },
) =>
  client.post(
    `/shop_admin_app/export_aggregation_csv/nikkei_by_shops`,
    { shopIds, targetDate },
    getRequestConfig(context, { responseType: "blob" }),
  );

const downloadGmoTransactionAggregatedData = (
  context: Context,
  { start, end, shopId }: DownloadRangeAggregatedDataParams,
) => {
  const startDateString = `${start.year}${String(start.month).padStart(2, "0")}${String(
    start.date,
  ).padStart(2, "0")}`;
  const endDateString = `${end.year}${String(end.month).padStart(2, "0")}${String(
    end.date,
  ).padStart(2, "0")}`;

  return onlinePaymentClient.post(
    `/online_payment/shop_admin_app/export_aggregation_csv/query?${stringify({
      start: startDateString,
      end: endDateString,
    })}`,
    {
      start: startDateString,
      end: endDateString,
      shopId,
    },
    getRequestConfig(context, { responseType: "blob" }),
  );
};

const downloadAdyenTerminalPaymentAggregatedData = (
  context: Context,
  { targetDate, shopId }: { targetDate: string; shopId: string },
) =>
  onlinePaymentClient.post(
    `/online_payment/shop_admin_app/export_aggregation_csv/adyen_terminal_payment_query`,
    {
      targetDate,
      shopId,
    },
    getRequestConfig(context, { responseType: "blob" }),
  );

const pikaichiUpload = (context: Context, date: Date) => {
  const { shopId } = context;
  const queryDate = dayjs(date).format("YYYY-MM-DD");

  return client.get(
    `/pikaichi/shops/${shopId}/upload?${stringify({ queryDate })}`,
    getRequestConfig(context, {}),
  );
};

const pikaichiGetZipToUpload = (context: Context, date: Date) => {
  const { shopId } = context;
  const queryDate = dayjs(date).format("YYYY-MM-DD");
  return client.get(
    `/pikaichi/shops/${shopId}/zip_to_upload?${stringify({ queryDate })}`,
    getRequestConfig(context, { responseType: "blob" }),
  );
};

const tecUpload = (
  context: Context,
  date: Date,
  integrationType: TecAggregationIntegrationTypeEnum,
) => {
  const { shopId } = context;

  const targetDate = dayjs(date).format("YYYY-MM-DD");

  switch (integrationType) {
    case TecAggregationIntegrationTypeEnum.GulfNet:
      return client.post(
        `/gulf_net/shops/${shopId}/upload?${stringify({ targetDate })}`,
        null,
        getRequestConfig(context, {}),
      );
    case TecAggregationIntegrationTypeEnum.Aspit:
      return client.post(
        `/aspit/shops/${shopId}/upload?${stringify({ targetDate })}`,
        null,
        getRequestConfig(context, {}),
      );
    case TecAggregationIntegrationTypeEnum.Datatrade:
      return client.post(
        `/datatrade/shops/${shopId}/upload?${stringify({ targetDate })}`,
        null,
        getRequestConfig(context, {}),
      );
    case TecAggregationIntegrationTypeEnum.MierukaSystem:
      return client.post(
        `/mieruka_system/shops/${shopId}/upload?${stringify({ targetDate })}`,
        null,
        getRequestConfig(context, {}),
      );
    case TecAggregationIntegrationTypeEnum.MakaseteNet:
      return client.post(
        `/makasete_net/shops/${shopId}/upload?${stringify({ targetDate })}`,
        null,
        getRequestConfig(context, {}),
      );
    case TecAggregationIntegrationTypeEnum.Hanzo:
      return client.post(
        `/hanzo/shops/${shopId}/upload?${stringify({ targetDate })}`,
        null,
        getRequestConfig(context, {}),
      );
    case TecAggregationIntegrationTypeEnum.Rakmy:
      return client.post(
        `/rakmy/shops/${shopId}/upload?${stringify({ targetDate })}`,
        null,
        getRequestConfig(context, {}),
      );
    default:
      throw new UnreachableError(integrationType);
  }
};

const tecGetZipToUpload = (
  context: Context,
  date: Date,
  integrationType: TecAggregationIntegrationTypeEnum,
) => {
  const { shopId } = context;

  const targetDate = dayjs(date).format("YYYY-MM-DD");

  switch (integrationType) {
    case TecAggregationIntegrationTypeEnum.GulfNet:
      return client.get(
        `/gulf_net/shops/${shopId}/zip_to_upload?${stringify({ targetDate })}`,
        getRequestConfig(context, { responseType: "blob" }),
      );
    case TecAggregationIntegrationTypeEnum.Aspit:
      return client.get(
        `/aspit/shops/${shopId}/zip_to_upload?${stringify({ targetDate })}`,
        getRequestConfig(context, { responseType: "blob" }),
      );
    case TecAggregationIntegrationTypeEnum.Datatrade:
      return client.get(
        `/datatrade/shops/${shopId}/zip_to_upload?${stringify({ targetDate })}`,
        getRequestConfig(context, { responseType: "blob" }),
      );
    case TecAggregationIntegrationTypeEnum.MierukaSystem:
      return client.get(
        `/mieruka_system/shops/${shopId}/zip_to_upload?${stringify({ targetDate })}`,
        getRequestConfig(context, { responseType: "blob" }),
      );
    case TecAggregationIntegrationTypeEnum.MakaseteNet:
      return client.get(
        `/makasete_net/shops/${shopId}/zip_to_upload?${stringify({ targetDate })}`,
        getRequestConfig(context, { responseType: "blob" }),
      );
    case TecAggregationIntegrationTypeEnum.Hanzo:
      return client.get(
        `/hanzo/shops/${shopId}/zip_to_upload?${stringify({ targetDate })}`,
        getRequestConfig(context, { responseType: "blob" }),
      );
    case TecAggregationIntegrationTypeEnum.Rakmy:
      return client.get(
        `/rakmy/shops/${shopId}/zip_to_upload?${stringify({ targetDate })}`,
        getRequestConfig(context, { responseType: "blob" }),
      );

    default:
      throw new UnreachableError(integrationType);
  }
};

const tecGetMasterCsv = (context: Context) =>
  client.get(`/tec/master`, getRequestConfig(context, { responseType: "blob" }));

type DownloadWinboardMenuRegistrationCsvParams = {
  shopId: string;
};

const downloadWinboardMenuRegistrationCsv = (
  context: Context,
  { shopId }: DownloadWinboardMenuRegistrationCsvParams,
) =>
  client.get(
    `/winboard/shops/${shopId}/menu_registration_csv/`,
    getRequestConfig(context, { responseType: "blob" }),
  );

const winboardUpload = (context: Context, date: Date) => {
  const { shopId } = context;
  const queryDate = dayjs(date).format("YYYY-MM-DD");

  return client.get(
    `winboard/shops/${shopId}/upload?${stringify({ queryDate })}`,
    getRequestConfig(context, {}),
  );
};

const winboardGetZipToUpload = (context: Context, date: Date) => {
  const { shopId } = context;
  const queryDate = dayjs(date).format("YYYY-MM-DD");

  return client.get(
    `winboard/shops/${shopId}/zip_to_upload?${stringify({ queryDate })}`,
    getRequestConfig(context, { responseType: "blob" }),
  );
};

const foodingJournalGetFileToPut = (context: Context, date: Date) => {
  const { shopId } = context;
  const queryDate = dayjs(date).format("YYYY-MM-DD");

  return client.get(
    `fooding_journal/${shopId}/get_file_to_put?${stringify({ queryDate })}`,
    getRequestConfig(context, { responseType: "blob" }),
  );
};

type InputMasterDataResponse = {
  result: boolean;
  index?: number;
  csvName?: string;
  menuCode?: string;
  dpCode?: string;
  dpName?: string;
  gpCode?: string;
  gpName?: string;
};

const inputMasterData = (context: Context, data: FormData) =>
  client.post<InputMasterDataResponse>(
    "/shop_admin_app/input_master_data",
    data,
    getRequestConfig(context, mergeCustomHeaders({}, { "content-type": "multipart/form-data" })),
  );

const downloadInputShopDataTemplate = (context: Context, data: { companyId: number }) =>
  client.post(
    "/shop_admin_app/input_shop_data/template",
    data,
    getRequestConfig(context, { responseType: "blob" }),
  );

type InputShopDataResponse = { result: boolean };

const inputShopData = (context: Context, data: FormData) =>
  client.post<InputShopDataResponse>(
    "/shop_admin_app/input_shop_data",
    data,
    getRequestConfig(
      context,
      mergeCustomHeaders({ responseType: "blob" }, { "content-type": "multipart/form-data" }),
    ),
  );

const convertTecFormat = (context: Context, data: FormData) =>
  client.post(
    "/shop_admin_app/convert_tec_format",
    data,
    getRequestConfig(
      context,
      mergeCustomHeaders({ responseType: "blob" }, { "content-type": "multipart/form-data" }),
    ),
  );

type UploadQuestionnaireImageData = FormData;

type UploadQuestionnaireImageResponse = { url: string };

const uploadQuestionnaireImage = (
  context: Context,
  data: UploadQuestionnaireImageData,
  config: AxiosRequestConfig = {},
) =>
  client.post<UploadQuestionnaireImageResponse>(
    "/shop_admin_app/questionnaire/upload_image",
    data,
    getRequestConfig(
      context,
      mergeCustomHeaders(config, { "content-type": "multipart/form-data" }),
    ),
  );

type DownloadQuestionnaireAnswersParams = {
  questionnaireId: string;
  corporationId: string;
  shopIds: string[];
  startDate: string;
  endDate: string;
};

const downloadQuestionnaireAnswers = (
  context: Context,
  {
    questionnaireId,
    corporationId,
    shopIds,
    startDate,
    endDate,
  }: DownloadQuestionnaireAnswersParams,
) =>
  client.post(
    `/shop_admin_app/questionnaire_answer/download?start=${startDate}&end=${endDate}`,
    {
      questionnaireId,
      corporationId,
      shopIds,
      start: startDate,
      end: endDate,
    },
    getRequestConfig(context, { responseType: "blob" }),
  );

type UploadConnectGameConfigImageData = FormData;

type UploadConnectGameConfigImageResponse = { url: string };

const uploadConnectGameConfigImage = (
  context: Context,
  data: UploadConnectGameConfigImageData,
  config: AxiosRequestConfig = {},
) =>
  client.post<UploadConnectGameConfigImageResponse>(
    "/shop_admin_app/connect_game_config/upload_image",
    data,
    getRequestConfig(
      context,
      mergeCustomHeaders(config, { "content-type": "multipart/form-data" }),
    ),
  );

type VerifyMenuCodeCsvData = FormData;
const verifyMenuCodeCsv = (context: Context, data: VerifyMenuCodeCsvData) =>
  client.post(
    "/shop_admin_app/bi_menu_code/verify_menu_code_csv",
    data,
    getRequestConfig(context, mergeCustomHeaders({}, { "content-type": "multipart/form-data" })),
  );

type UploadMenuCodeCsvData = FormData;
const uploadMenuCodeCsv = (context: Context, data: UploadMenuCodeCsvData) =>
  client.post(
    "/shop_admin_app/bi_menu_code/update_menu_code_csv",
    data,
    getRequestConfig(context, mergeCustomHeaders({}, { "content-type": "multipart/form-data" })),
  );
const downloadMenuCodeCsv = (
  companyId: string,
  biType: string,
  context: Context,
  config: AxiosRequestConfig = {},
) =>
  client.get(`/shop_admin_app/bi_menu_code/menu_csv?companyId=${companyId}&bi_type=${biType}`, {
    ...getRequestConfig(context, mergeCustomHeaders(config, {})),
    responseType: "blob",
  });

export const dinii = {
  mobileOrderWelcomeScreenSettings: { uploadImage: uploadMobileOrderWelcomeScreenSettingImage },
  plan: { uploadImage: uploadPlanImage },
  menu: { uploadImage: uploadMenuImage },
  choice: { uploadImage: uploadChoiceImage },
  planChoice: { uploadImage: uploadPlanChoiceImage },
  coupon: { uploadImage: uploadCouponImage },
  accountingSlipImage: {
    uploadImage: uploadAccountingSlipImage,
  },
  category: { uploadImage: uploadCategoryImage },
  menuMasterCsv: {
    verify: verifyMenuMasterCsv,
    upload: uploadMenuMasterCsv,
    downloadMenuCsv: downloadMenuMasterCsv,
    downloadOptionCsv: downloadMenuMasterOptionCsv,
  },
  messageImage: { uploadImage: uploadMessageImage },
  aggregatedData: {
    range: { download: downloadRangeAggregatedData },
    gmoTransaction: { download: downloadGmoTransactionAggregatedData },
    nikkeiByShops: { download: downloadNikkeiAggregationCsvByShopsData },
    adyenTerminalPayment: { download: downloadAdyenTerminalPaymentAggregatedData },
  },
  lineReportingManualImage: {
    generateDailyReport,
    generateWeeklyReport,
    generateMonthlyReport,
  },
  pikaichi: {
    upload: pikaichiUpload,
    getZipToUpload: pikaichiGetZipToUpload,
  },
  tec: {
    upload: tecUpload,
    getZipToUpload: tecGetZipToUpload,
    getMasterCsv: tecGetMasterCsv,
  },
  winboard: {
    getMenuRegistrationCsv: downloadWinboardMenuRegistrationCsv,
    upload: winboardUpload,
    getZipToUpload: winboardGetZipToUpload,
  },
  foodingJournal: {
    getFileToPut: foodingJournalGetFileToPut,
  },
  inputMasterData,
  downloadInputShopDataTemplate,
  inputShopData,
  convertTecFormat,
  questionnaire: {
    uploadImage: uploadQuestionnaireImage,
    download: downloadQuestionnaireAnswers,
  },
  connectGameConfig: { uploadImage: uploadConnectGameConfigImage },
  shopIconImage: { uploadImage: uploadShopIconImage },
  menuCodeCsv: {
    verify: verifyMenuCodeCsv,
    upload: uploadMenuCodeCsv,
    download: downloadMenuCodeCsv,
  },
};
