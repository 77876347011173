import React, { memo } from "react";
import styled from "styled-components";
import { Button, Flex, Typography } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { FormContent } from "components/Template/FormTemplate";
import { colors } from "constants/colors";

import { AdyenPaymentStoreMerchantCategory } from "../types";

import { MerchantCategoryField } from "./MerchantCategoryField";
import {
  AddShopCashlessSettingMerchantCategoryFormValues,
  useAddShopCashlessSettingMerchantCategoryForm,
} from "./useAddShopCashlessSettingMerchantCategoryForm";

type Props = {
  merchantCategory: AdyenPaymentStoreMerchantCategory | null;
  loading: boolean;
  onSubmit: (values: AddShopCashlessSettingMerchantCategoryFormValues) => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
};

const StyledForm = styled(Form)`
  border: 1px solid ${colors.Divider.Default};
  padding: 24px;
`;

export const AddShopCashlessSettingMerchantCategoryForm = memo<Props>(
  ({ merchantCategory, loading, onSubmit, onFormValidationError }) => {
    const { form, initialValues, submit } = useAddShopCashlessSettingMerchantCategoryForm({
      merchantCategory,
      onSubmit,
      onFormValidationError,
    });

    return (
      <>
        <FormContent>
          <StyledForm name="shop" form={form} layout="vertical" initialValues={initialValues}>
            <Typography.Title level={3}>MCC</Typography.Title>
            <Flex justify="space-between">
              <MerchantCategoryField disabled={Boolean(merchantCategory)} />
              <FormActions>
                <Button
                  type="primary"
                  onClick={submit}
                  loading={loading}
                  disabled={Boolean(merchantCategory)}
                >
                  登録
                </Button>
              </FormActions>
            </Flex>
          </StyledForm>
        </FormContent>
      </>
    );
  },
);
